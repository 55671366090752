import { Alert } from '@app/models/alert';
import { Types } from './types';

type Action = {
  type: string;
  alert?: Alert;
};

const add = (alert: Alert): Action => ({
  type: Types.ADD,
  alert,
});

const remove = (alert: Alert): Action => ({
  type: Types.REMOVE,
  alert,
});

export { add, remove, Action };
