import { getValue } from '@bespohk/lib';

const valueExistsInArrayOfObjects = (
  dot: string,
  needle: any, // eslint-disable-line
  haystack: any[], // eslint-disable-line
): boolean => {
  const valueToFind = getValue(needle, dot);

  return !!haystack.find((item) => valueToFind === getValue(item, dot));
};

export { valueExistsInArrayOfObjects };
