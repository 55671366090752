import { Arrow } from './arrows';
import { default as Model } from '@app/models/panel';
import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './panel.module.css';

type OwnProps = {
  children: any;
  panel: Model;
  isDrawing?: boolean;
};

const cx = classNames.bind(styles);

const Panel = ({ children, panel, isDrawing }: OwnProps) => {
  if (!panel.hasComponents) {
    return null;
  }

  return (
    <div className={cx({ outer: true, isDrawing })}>
      <Arrow
        layout="horizontal"
        label={`${panel.width}mm`}
        isDrawing={isDrawing}
      />
      <Arrow
        layout="vertical"
        label={`${panel.height}mm`}
        isDrawing={isDrawing}
      />
      {children}
    </div>
  );
};

export { Panel };
export default Panel;
