import { Icon, Icons } from '@bespohk/uikit/components';

import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './boolean-column-renderer.module.css';

const cx = classNames.bind(styles);

type OwnProps = {
  valid?: boolean;
};

const BooleanColumnRenderer = ({ valid = false }: OwnProps) => {
  return (
    <Icon
      className={cx({ valid: true, invalid: !valid })}
      name={valid ? Icons.CheckCircle : Icons.Circle}
    />
  );
};

export { BooleanColumnRenderer };
export default BooleanColumnRenderer;
