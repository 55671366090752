import { hydrate } from '@bespohk/lib';
import PlateFinish from './plate-finish';
import PanelType from './panel-type';

class Route {
  id: number;
  minutes: number;
  code: string;
  routeId: string;
  enabled: boolean;

  @hydrate(PlateFinish)
  plateFinish: PlateFinish;

  @hydrate(PanelType)
  panelType: PanelType;

  public toString(): string {
    return this.code;
  }
}

const findRoute = (
  routes: Route[],
  minutes: number,
  panelType: PanelType,
  plateFinish?: PlateFinish,
) => {
  const matcher = plateFinish
    ? (route) =>
        route.enabled &&
        route.minutes === minutes &&
        route.panelType.id === panelType.id &&
        plateFinish.id === route?.plateFinish?.id
    : (route) => {
        return (
          route.enabled &&
          route.minutes === minutes &&
          route.panelType.id === panelType.id &&
          !route.plateFinish
        );
      };

  return routes.find(matcher);
};

export { Route, findRoute };
export default Route;
