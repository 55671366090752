import * as yup from 'yup';

import { Data } from '@app/models/component';
import { Input } from '@bespohk/uikit/components';
import { ModelForm } from '@app/containers';
import * as React from 'react';

type OwnProps = unknown;

const ComponentData = (_: OwnProps) => {
  return (
    <ModelForm
      identifier="id"
      endpoint="/components/datas"
      type={Data}
      validate={yup.object().shape({
        typeOrEquipmentCode: yup.string().required('Type is a required field.'),
        options: yup
          .string()
          .required(
            'Options is a required field, and must be comma separated.',
          ),
      })}
      fieldsets={[
        [
          {
            name: 'typeOrEquipmentCode',
            label: 'Type or Equipment Code',
            renderer: Input,
            props: { required: true },
          },
        ],
        [{ name: 'options', renderer: Input, props: { required: true } }],
      ]}
    />
  );
};

export { ComponentData };
export default ComponentData;
