import { PanelSet } from '@app/models/panel-set';
import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './parts.module.css';

type OwnProps = {
  panelSet: PanelSet;
};

const cx = classNames.bind(styles);

const Cutout = ({ panelSet }: OwnProps) => {
  const { dimensions } = panelSet.backplate;

  return (
    <div className={cx({ cutout: true })}>
      Wall Box Depth - {panelSet.backplate.depth}mm
      <br />
      Cut Out Size: {dimensions.width} W x {dimensions.height} H
    </div>
  );
};

export { Cutout };
export default Cutout;
