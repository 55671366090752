import * as yup from 'yup';

import {
  Button,
  ButtonBar,
  Field,
  Form,
  Image,
  Input,
  Panel,
} from '@bespohk/uikit/components';
import { operations } from '@app/state/ducks/auth/operations';

import { Alerts } from '@app/containers';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import State from '@app/state';
import Token from '@app/models/token';
import { asset } from '@app/helpers/assets';
import styles from './login.module.css';
import { useOperations } from '@app/helpers/redux';
import { useSelector } from 'react-redux';

type OwnProps = unknown;

const Login = (_: OwnProps) => {
  const token: Token = useSelector((state: State) => state.auth.data.token);

  if (token) {
    return <Redirect to="/" />;
  }

  const { login } = useOperations(operations);

  return (
    <>
      <div className={styles.outer}>
        <Panel>
          <Image src={asset('/img/logo.svg')} className={styles.logo} />
          <Form<{ email: string; password: string }>
            onSubmit={(credentials) =>
              login(credentials.email, credentials.password)
            }
            validate={yup.object().shape({
              email: yup.string().required('You must supply an email address.'),
              password: yup.string().required('You must supply a password.'),
            })}
          >
            <Field required>
              <Input name="email" />
            </Field>
            <Field required>
              <Input name="password" type="password" />
            </Field>
            <ButtonBar>
              <Button label="Login" type="submit" />
            </ButtonBar>
          </Form>
        </Panel>
      </div>
      <Alerts />
    </>
  );
};

export { Login };
export default Login;
