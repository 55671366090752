import { Layout } from './layout';
import * as React from 'react';

type OwnProps = unknown;

const Factory = (_: OwnProps) => {
  return <Layout type="factory" />;
};

export { Factory };
export default Factory;
