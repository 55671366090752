import * as yup from 'yup';

import Model from '@app/models/tool-type';
import { Input } from '@bespohk/uikit/components';
import { ModelForm } from '@app/containers';
import * as React from 'react';

type OwnProps = unknown;

const ToolType = (_: OwnProps) => {
  return (
    <ModelForm
      identifier="id"
      endpoint="/components/tool-types"
      type={Model}
      validate={yup.object().shape({
        code: yup.string().required('Code is a required field.'),
      })}
      fieldsets={[
        [
          {
            name: 'code',
            renderer: Input,
            props: { required: true },
          },
        ],
      ]}
    />
  );
};

export { ToolType };
export default ToolType;
