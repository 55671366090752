class ToolType {
  code: string;
  id: number;

  public toString(): string {
    return this.code;
  }
}

export default ToolType;
