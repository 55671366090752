import * as React from 'react';
import { API_ENDPOINT } from '@app/helpers/constants';
import State from '@app/state';
import { useSelector } from 'react-redux';

const usePrinting = (): [boolean, (name: string) => void] => {
  const [printing, setPrinting] = React.useState(false);

  const print = (name: string) => {
    setPrinting(true);
    document.documentElement.style.scrollBehavior = 'unset';
    window.scrollTo(0, 0);
    const element = document.body;
    const opt = {
      margin: 0,
      filename: `${name}.pdf`,
      image: { type: 'jpeg', quality: 1 },
      pagebreak: {
        after: '.break',
      },
      html2canvas: {
        scale: 1.5,
        useCORS: true,
        proxy: `${API_ENDPOINT}/components/imageproxy`,
      },
      jsPDF: {
        unit: 'px',
        format: 'a3',
        orientation: 'landscape',
        hotfixes: ['px_scaling'],
      },
    };

    const generatePdf = async (pages) => {
      const firstPage = pages.shift();

      let worker = window.html2pdf().set(opt).from(firstPage);

      if (pages.length) {
        worker = worker.toPdf();
        pages.forEach((page) => {
          worker = worker
            .get('pdf')
            .then((pdf) => {
              pdf.addPage();
            })
            .from(page)
            .toContainer()
            .toCanvas()
            .toPdf();
        });
      }

      return worker.save();
    };

    // Timeout so it has a chance to update the dimensions
    setTimeout(() => {
      generatePdf(
        // eslint-disable-next-line
        Array.from(element.querySelectorAll('[class^="page__"]')),
      ).then(() => {
        setPrinting(false);
      });
    }, 1000);
  };

  return [printing, print];
};

const useLookups = () => {
  const lookupSelector = (state: State) => state.panelBuilder.lookups;

  return useSelector(lookupSelector);
};

export { usePrinting, useLookups };
