import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './parts.module.css';

const cx = classNames.bind(styles);

type OwnProps = {
  children: React.ReactNode[] | React.ReactNode;
  position: 'top' | 'bottom';
  className?: string;
};

const Part = ({ children, position, className }: OwnProps) => {
  return (
    <div className={cx(className, { part: true, [position]: true })}>
      {children}
    </div>
  );
};

export { Part };
export default Part;
