const API_ENDPOINT = process.env.API_ENDPOINT;

const INVALID_GANG = 'Unknown';

const DEFAULT_HANDLING_FEE = 8;

const HANDLING_FEES = [
  120, 60, 40, 30, 24, 20, 17.15, 15, 13.33, 12, 10.91, 10, 9.23, 8.57,
];

const TOKEN_KEY = 'token_v2';
const USER_KEY = 'user_v2';

const HEIGHT_OFFSET = 40;

export {
  API_ENDPOINT,
  HANDLING_FEES,
  DEFAULT_HANDLING_FEE,
  INVALID_GANG,
  TOKEN_KEY,
  USER_KEY,
  HEIGHT_OFFSET,
};
