import '@app/assets/css/vendor/reset.css';
import '@app/assets/css/base.css';

import { App } from './app';
import * as React from 'react';
import { createRoot } from 'react-dom/client';

import * as Sentry from '@sentry/browser';

import { logger } from './helpers/log';

const log = logger('index');

Sentry.init({
  dsn: process.env.SENTRY_DSN,
});
log('Initialised Sentry', process.env.SENTRY_DSN);

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(<App />);
