import * as yup from 'yup';

import { Route as Model } from '@app/models/route';
import { Input, Select } from '@bespohk/uikit/components';
import { ModelForm } from '@app/containers';
import * as React from 'react';
import { Choice } from '@bespohk/uikit';
import {
  choiceFromBoolean,
  choiceToBoolean,
  paginatedPanelTypeOptions,
  paginatedPlateFinishOptions,
} from '@app/helpers/form';
import { lookup } from '@app/helpers/api';
import PlateFinish from '@app/models/plate-finish';
import PanelType from '@app/models/panel-type';

type OwnProps = unknown;

const Route = (_: OwnProps) => {
  return (
    <ModelForm
      identifier="id"
      endpoint="/routes"
      type={Model}
      validate={yup.object().shape({
        minutes: yup.string().required('Minutes is a required field.'),
        code: yup.string().required('Code is a required field.'),
        routeId: yup.string().required('Route ID is a required field.'),
      })}
      initialTransform={yup.object().shape({
        enabled: choiceFromBoolean(),
        plateFinish: yup.mixed().convert('.'),
        panelType: yup.mixed().convert('.'),
      })}
      preValidateTransform={yup.object().shape({
        enabled: choiceToBoolean(),
        plateFinish: yup.mixed().convert('id'),
        panelType: yup.mixed().convert('id'),
      })}
      fieldsets={[
        [
          {
            name: 'code',
            renderer: Input,
            props: { required: true },
          },
        ],
        [
          {
            name: 'minutes',
            renderer: Input,
            props: { required: true },
          },
          {
            name: 'routeId',
            renderer: Input,
            props: { required: true },
          },
        ],
        [
          {
            name: 'plateFinish',
            renderer: Select,
            props: {
              required: true,
              openOnFocus: true,
              async: lookup(
                PlateFinish,
                '/plate-finishes',
                paginatedPlateFinishOptions,
                (q: string) => (q ? `(name:${q}){*}` : null),
              ),
            },
          },
          {
            name: 'panelType',
            renderer: Select,
            props: {
              required: true,
              openOnFocus: true,
              async: lookup(
                PanelType,
                '/panel-types',
                paginatedPanelTypeOptions,
                (q: string) => (q ? `(name:${q}){*}` : null),
              ),
            },
          },
        ],
        [
          {
            name: 'enabled',
            label: null,
            renderer: Choice,
            props: {
              options: [{ value: true, label: 'Enabled' }],
            },
          },
        ],
      ]}
    />
  );
};

export { Route };
export default Route;
