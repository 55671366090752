import Inclusion, { Line as LineItem } from '@app/models/inclusion';

import { Cell } from './cell';
import * as React from 'react';
import { Table } from './table';
import classNames from 'classnames/bind';
import { formatter } from '@app/helpers/currency';
import styles from './inclusions.module.css';

const cx = classNames.bind(styles);

type OwnProps = {
  inclusion: Inclusion;
};

type LineProps = {
  line: LineItem;
};

const Line = ({ line }: LineProps) => {
  const { customerReference, quantity, supplyMethod, componentCounts } = line;

  return (
    <tr>
      <Cell value={customerReference} vertical />
      <Cell value={quantity} vertical />
      <Cell
        value={supplyMethod.map((method) => (
          <div key={method.type}>{method.type}</div>
        ))}
      />
      <Cell
        value={supplyMethod.map((method, index) => (
          <div key={method.type} className={cx({ light: index === 2 })}>
            {method.partNumber}
          </div>
        ))}
      />
      <Cell
        value={supplyMethod.map((method, index) => (
          <div key={method.type} className={cx({ bold: index === 2 })}>
            {formatter.format(method.price)}
          </div>
        ))}
      />
      <Cell
        value={supplyMethod.map((method, index) => (
          <div key={method.type} className={cx({ bold: index === 2 })}>
            {formatter.format(method.priceTotal)}
          </div>
        ))}
      />
      {Object.keys(componentCounts).map((equipmentCode) => (
        <Cell
          key={equipmentCode}
          vertical
          value={
            componentCounts[equipmentCode] ? componentCounts[equipmentCode] : ''
          }
        />
      ))}
    </tr>
  );
};

const Breakdown = ({ inclusion }: OwnProps) => {
  const { lines, categories } = inclusion;

  const cats = Object.keys(categories);

  const headers = lines.length ? Object.keys(lines[0].componentCounts) : [];

  return (
    <Table className={cx({ breakdown: true })}>
      <thead>
        <tr>
          <Cell colSpan={6} className={cx({ blank: true, spacer: true })} />
          <Cell header value="Inclusions" colSpan={headers.length} />
        </tr>
        <tr>
          <th colSpan={6} className={cx({ blank: true })} />
          {cats.map((cat, index) => (
            <Cell
              key={cat}
              header
              value={cat}
              className={cx({
                lowlight: index % 2 === 0,
                alt: index % 2 !== 0,
              })}
              colSpan={Object.keys(categories[cat]).length}
            />
          ))}
        </tr>
        <tr>
          <Cell header value="Customer Reference" />
          <Cell header value="Qty" />
          <Cell header value="Supply Method" />
          <Cell header value="Part Number" />
          <Cell header value="Price - EACH ex GST" />
          <Cell header value="Price - Total ex GST" />
          {headers.map((header) => (
            <Cell
              value={header}
              key={header}
              className={cx({ equipmentCode: true })}
            />
          ))}
        </tr>
      </thead>
      <tbody>
        {lines.map((line) => (
          <Line line={line} key={line.customerReference} />
        ))}
      </tbody>
      <tfoot>
        <tr>
          <Cell value="Overall Total" lowlight footer />
          <Cell value={inclusion.totalMsp} lowlight footer />
          <Cell value="" lowlight footer colSpan={3} />
          <Cell lowlight footer value={formatter.format(inclusion.totalCost)} />
          <Cell lowlight footer colSpan={headers.length} />
        </tr>
      </tfoot>
    </Table>
  );
};

export { Breakdown };
export default Breakdown;
