import { Layout } from '@app/containers/layout';
import { Provider } from 'react-redux';
import * as React from 'react';
import { Theme } from '@bespohk/uikit/components';
import buttonStyles from '@app/assets/css/theme/button.module.css';
import { configureStore } from '@app/state';
import fieldStyles from '@app/assets/css/theme/field.module.css';
import inputStyles from '@app/assets/css/theme/input.module.css';
import loaderStyles from '@app/assets/css/theme/loader.module.css';
import pageReadStyles from '@app/assets/css/theme/page-read.module.css';
import paginationStyles from '@app/assets/css/theme/pagination.module.css';
import selectStyles from '@app/assets/css/theme/select.module.css';
import tableStyles from '@app/assets/css/theme/table.module.css';
import wrapperStyles from '@app/assets/css/theme/wrapper.module.css';

const App = () => (
  // @ts-ignore NOTE: This works
  <Provider store={configureStore()}>
    <Theme
      components={{
        Button: buttonStyles,
        Table: tableStyles,
        Pagination: paginationStyles,
        Input: inputStyles,
        Wrapper: wrapperStyles,
        Field: fieldStyles,
        Select: selectStyles,
        Loader: loaderStyles,
        PageRead: pageReadStyles,
      }}
    >
      <Layout />
    </Theme>
  </Provider>
);

export { App };
export default App;
