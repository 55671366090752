import * as actions from './actions';
import * as operations from './operations';
import * as selectors from './selectors';
import * as types from './types';

import { reducer } from './reducers';

export { operations, actions, types, selectors };

export default reducer;
