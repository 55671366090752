import { Alert } from '@app/models/alert';
import { Alerts as AlertsComponent } from '@app/components';
import * as React from 'react';
import State from '@app/state';
import { useSelector } from 'react-redux';

type OwnProps = unknown;

const Alerts = (_: OwnProps) => {
  const alerts: Alert[] = useSelector((state: State) => state.alerts);

  return <AlertsComponent alerts={alerts} />;
};

export { Alerts };
export default Alerts;
