import { default as Model } from '@app/models/component';
import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './component.module.css';
import { Costs } from '../costs'; // eslint-disable-line
import { Route } from '../route'; // eslint-disable-line

type OwnProps = {
  component: Model;
  className?: string;
  children?: any;
  hasStrapping?: boolean;
  showCosts?: boolean;
  showRoutes?: boolean;
};

const cx = classNames.bind(styles);

const EngravedLine = ({
  vertical = false,
  component,
}: {
  vertical?: boolean;
  component: Model;
}) => {
  let dimensions = { width: '1', height: '100%' };
  let line = { x1: 0, y1: 40, x2: 0, y2: 120 };
  let style = {};
  if (vertical) {
    dimensions = { width: '100%', height: '1' };
    line = { x1: 0, x2: component.width, y1: 0, y2: 0 };
    style = { position: 'absolute' };
  }

  return (
    <svg {...dimensions} style={style}>
      <line {...line} stroke="black" strokeDasharray="4"></line>
    </svg>
  );
};

const Component = ({
  component,
  children,
  className,
  hasStrapping,
  showCosts,
  showRoutes,
  ...rest
}: OwnProps & React.HTMLAttributes<HTMLDivElement>) => {
  const [date] = React.useState(Date.now());
  const style: React.CSSProperties = {
    width: `${component.width}px`,
  };

  if (!component.isSpacer) {
    style.backgroundImage = `url("${process.env.ASSET_BASE}/img/components/${component.svg}?d=${date}")`;
  }

  const generateOffset = (index) => {
    let paddingLeft = 0;
    let paddingRight = 0;
    const paddingTop = component.offset.y + (hasStrapping ? 20 : 0);

    if (component.quantity === 3) {
      if (index === 0) {
        paddingLeft = component.offset.x;
      } else if (index === 2) {
        paddingRight = component.offset.x;
      }
    }
    if (component.quantity === 2) {
      if (index === 0) {
        paddingLeft = component.offset.x;
      } else if (index === 1) {
        paddingRight = component.offset.x;
      }
    }

    return {
      paddingLeft,
      paddingRight,
      paddingTop,
    };
  };

  return (
    <div style={{ display: 'flex' }}>
      {component.showEngravedLineBefore && (
        <EngravedLine component={component} />
      )}
      {component.showEngravedLineTop && (
        <EngravedLine vertical component={component} />
      )}
      <div
        className={cx({ component: true, [className]: !!className })}
        style={style}
        {...rest}
      >
        {!component.isSpacer && (
          <div className={cx({ code: true })}>
            {component.equipmentCode}
            <Costs cost={showCosts ? component.humanCost : null} />
          </div>
        )}
        {showRoutes && (
          <Route
            tooltypes={component.toolTypes}
            allocation={component.psRouteAllocation}
            horizontalPunchPositions={component.horPunchPositions}
            verticalPunchPositions={component.vertPunchPositions}
          />
        )}
        {component.data && (
          <div className={cx({ labelWrapper: true })}>
            {component.componentData.map((data, i) => (
              <div
                key={i}
                className={cx({ label: true })}
                style={generateOffset(i)}
              >
                {data}
              </div>
            ))}
          </div>
        )}
        {children}
      </div>
      {component.showHbar && <div className={cx({ hbar: true })}></div>}
    </div>
  );
};

export { Component };
export default Component;
