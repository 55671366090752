import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './inclusions.module.css';

const cx = classNames.bind(styles);

type OwnProps = {
  label: string;
  center?: boolean;
};

const Section = ({ label, center }: OwnProps) => {
  return (
    <div className={cx({ header: true, section: true, center })}>{label}</div>
  );
};

export { Section };
export default Section;
