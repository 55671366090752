import {
  Button,
  ButtonBar,
  Panel,
  Prompt,
  Table,
} from '@bespohk/uikit/components';
import * as React from 'react';

import { Discount } from './discount';
import { NavigationButton } from '@app/components';
import { default as PanelModel } from '@app/models/panel';
import { PanelSet } from '@app/models/panel-set';
import State from '@app/state';
import { Totals } from './totals';
import classNames from 'classnames/bind';
import { createSelector } from 'reselect';
import { scroll } from '@app/helpers/browser';
import styles from './overview.module.css';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const cx = classNames.bind(styles);

type OwnProps = unknown;

const quantityRenderer = (panelSet: PanelSet) => panelSet.panel.quantity;

const netRenderer = (panelSet: PanelSet) => (
  <div className={styles.net}>
    <div className={styles.individual}>{panelSet.humanNetUnitCost}</div>
    <div className={styles.combined}>{panelSet.humanNetDiscountedCost}</div>
  </div>
);

const projectSelector = (state: State) => state.project.data;
const panelSetSelector = (state: State) => state.panelBuilder.panelSet;

const dataSelector = createSelector(
  projectSelector,
  panelSetSelector,
  (project, panelSet) => {
    return { project, panelSet };
  },
);

const matchingPanel = (panelSet: PanelSet, panel: PanelModel): boolean =>
  panelSet.panel.equals(panel);

const Overview = (_: OwnProps) => {
  const { panelSet, project } = useSelector(dataSelector);

  const history = useHistory();
  const [changePanel, setChangePanel] = React.useState<PanelModel>(null);

  const title = (
    <div className={styles.title}>
      SO No. <div className={styles.soNumber}>{project.salesOrderNumber}</div>
    </div>
  );

  const panelSets = [...project.activePanelSets];
  if (!panelSet.panel.uuid) {
    // Currently working panel
    panelSets.push(panelSet);
  }

  return (
    <div>
      <Panel title={title} className={styles.panel}>
        <Table<PanelSet>
          className={styles.table}
          columns={[
            {
              key: 'mspReference' as any,
              label: 'Ref',
              renderer: (panelSet_: PanelSet) => (
                <NavigationButton
                  className={cx({
                    active: matchingPanel(panelSet, panelSet_.panel),
                  })}
                  size="small"
                  label={panelSet_.panel.mspReference}
                  path={`/projects/${project.uuid}/panels/${panelSet_.panel.uuid}`}
                  callback={() => {
                    if (matchingPanel(panelSet, panelSet_.panel)) {
                      return;
                    }
                    setChangePanel(
                      project.panels.find((panel) =>
                        matchingPanel(panelSet_, panel),
                      ),
                    );

                    return true;
                  }}
                />
              ),
            },
            {
              key: 'quantity' as any,
              label: 'Qty',
              renderer: quantityRenderer,
            },
            { key: 'net' as any, label: 'Trade/Net', renderer: netRenderer },
            {
              key: 'discount' as any,
              label: 'Discount %',
              renderer: (panelSet_: PanelSet) => {
                if (panelSet_.panel.expectedPrice) {
                  return 'N/A';
                }

                return (
                  panelSet_.panel.uuid && (
                    <Discount panel={panelSet_.panel} project={project} />
                  )
                );
              },
            },
          ]}
          data={panelSets}
        />
        <Totals project={project} />
      </Panel>
      {panelSet.panel.updatedDate && (
        <div className={cx('updated-date')}>
          Last updated: {panelSet.panel.humanUpdatedAt}
        </div>
      )}
      {panelSet.panel.xcode && (
        <div className={cx('updated-date')}>XCode: {panelSet.panel.xcode}</div>
      )}
      <ButtonBar layout="center">
        <Button
          label="Back to project"
          style="tertiary"
          action={() => {
            history.push(`/projects/${project.uuid}`);
          }}
        />
      </ButtonBar>
      <Prompt
        title="Switch panel?"
        message="Are you sure you wish to stop editing this panel?"
        when={!!changePanel}
        positive={{
          label: 'Ok',
          action: () => {
            history.push(
              `/projects/${project.uuid}/panels/${changePanel.uuid}`,
            );
            scroll();
            setChangePanel(null);
          },
        }}
        negative={{
          label: 'Cancel',
          action: () => setChangePanel(null),
        }}
      />
    </div>
  );
};

export { Overview };
export default Overview;
