import { Timestamps, mixin } from './common';

import Territory from './territory';
import { hydrate } from '@bespohk/lib';

class Customer {
  readonly uuid: string;
  businessName: string;
  phoneNumber: string;
  accountCode: string;
  address: string;
  suburb: string;
  state: Territory;
  postcode: string;
  enabled: boolean;
  @hydrate
  createdDate?: Date;
  @hydrate
  updatedDate?: Date;

  public get country(): string {
    return this.state.toLowerCase() === 'nz' ? 'NZ' : 'Australia';
  }

  public toString(): string {
    return this.businessName;
  }
}

interface Customer extends Timestamps {} // eslint-disable-line @typescript-eslint/no-empty-interface

mixin(Customer, [Timestamps]);

export default Customer;
