const zerofill = (str: unknown, count: number): string => {
  let newStr = str as string;
  while (newStr.toString().length < count) {
    newStr = `0${newStr}`;
  }

  return newStr;
};

const ifValue = (val: string, def = null): string => {
  return val ? val : def;
};

export { zerofill, ifValue };
