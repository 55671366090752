const Types = {
  GENERATE_ATTEMPT: '@@bom/GENERATE_ATTEMPT',
  GENERATE_SUCCESS: '@@bom/GENERATE_SUCCESS',
  EXPORT_REQUEST: '@bom/EXPORT_REQUEST',
  EXPORT_SUCCESS: '@bom/EXPORT_SUCCESS',
  EXPORT_ERROR: '@bom/EXPORT_ERROR',
};

export { Types };

export default Types;
