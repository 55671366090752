import { get, post } from '@app/helpers/api';
import {
  loginAttempt,
  loginSuccess,
  logoutAttempt,
  logoutSuccess,
} from './actions';

import { Dispatch } from 'redux';
import Token from '@app/models/token';
import User from '@app/models/user';
import { operations as alertOperations } from '../alerts/operations'; // eslint-disable-line
import { storage } from '@bespohk/lib';
import { TOKEN_KEY, USER_KEY } from '@app/helpers/constants';

const login = (email: string, password: string): any => {
  return (dispatch: Dispatch<any>): any => {
    dispatch(loginAttempt());

    return post('/auth/token', { email: email, password: password }, Token)
      .then((token: Token) => {
        storage.to(TOKEN_KEY, token);
        get('/users/me', User)
          .then((user: User) => {
            storage.to(USER_KEY, user);
            dispatch(loginSuccess(token, user));
            alertOperations.add(
              'success',
              `Welcome ${user.fullName}`,
              4,
            )(dispatch);
          })
          .catch((_) => {
            storage.remove(TOKEN_KEY);
            alertOperations.add(
              'error',
              'An error occurred, please contact your administrator.',
              4,
            )(dispatch);
          });
      })
      .catch((_) => {
        alertOperations.add(
          'error',
          'No active account found with the given credentials',
          4,
        )(dispatch);
      });
  };
};

const logout = (): any => {
  return (dispatch: Dispatch<any>): any => {
    storage.remove(TOKEN_KEY);
    storage.remove(USER_KEY);
    dispatch(logoutAttempt());
    dispatch(logoutSuccess());
  };
};

type Operations = {
  login: (email: string, password: string) => void;
  logout: () => void;
};

const operations: Operations = {
  login,
  logout,
};

export { login, operations, Operations };
