import * as yup from 'yup';

import { Component, Category } from '@app/models/component';
import { Protection, ProtectionOther } from '@app/models/panel';

import Contact from '@app/models/contact';
import Customer from '@app/models/customer';
import { Pagination } from '@app/state/ducks/types';
import PanelType from '@app/models/panel-type';
import PlateFinish from '@app/models/plate-finish';
import Series from '@app/models/series';
import { Status } from '@app/models/project';
import Territory from '@app/models/territory';
import Type from '@app/models/type';
import User from '@app/models/user';
// Required for convert
import { default as _ } from '@bespohk/uikit/components'; // eslint-disable-line
import { toTitleCase } from '@bespohk/lib';

const choiceFromBoolean = () =>
  yup.mixed().convert((value) => (value ? [true] : []));

const choiceToBoolean = () => yup.mixed().default(false).convert('.');

type Option = {
  label: string;
  value: unknown;
};

const categoryOptions: Option[] = Object.keys(Category).map((category) => ({
  label: Category[category],
  value: category,
}));

const typeOptions = (type: Type): Option[] => {
  return [{ label: type.name, value: type }];
};

const paginatedTypeOptions = (data: Pagination<Type>): Option[] =>
  data.results.map((type) => ({
    label: type.name,
    value: type,
  }));

const seriesOptions = (values: Series[] = []): Option[] =>
  values.map((value) => ({
    label: value.name,
    value: value,
  }));

const paginatedSeriesOptions = (data: Pagination<Series>): Option[] =>
  data.results.map((series) => ({
    label: series.name,
    value: series,
  }));

const customerOptions = (value: Customer): Option[] => {
  return [
    {
      label: value.businessName,
      value: value,
    },
  ];
};

const paginatedCustomerOptions = (data: Pagination<Customer>): Option[] =>
  data.results.map((customer) => ({
    label: `${customer.businessName} (${customer.accountCode})`,
    value: customer,
  }));

const territoryOptions: Option[] = Object.keys(Territory).map((territory) => ({
  label: Territory[territory],
  value: territory,
}));

const panelTypeOptions = (value: PanelType): Option[] => {
  return [
    {
      label: value.name,
      value: value,
    },
  ];
};

const paginatedPanelTypeOptions = (data: Pagination<PanelType>): Option[] =>
  data.results.map((panelType) => ({
    label: panelType.name,
    value: panelType,
  }));

const plateFinishOptions = (value: PlateFinish): Option[] => {
  return [
    {
      label: value.name,
      value: value,
    },
  ];
};

const paginatedPlateFinishOptions = (data: Pagination<PlateFinish>): Option[] =>
  data.results.map((plateFinish) => ({
    label: plateFinish.name,
    value: plateFinish,
  }));

const contactOptions = (value: Contact): Option[] => {
  return [
    {
      label: value.name,
      value: value,
    },
  ];
};

const paginatedContactOptions = (data: Pagination<Contact>): Option[] =>
  data.results.map((contact) => ({
    label: contact.name,
    value: contact,
  }));

const statusOptions: Option[] = Object.keys(Status).map((status) => ({
  label: Status[status],
  value: status,
}));

const userOptions = (value: User): Option[] => {
  return [
    {
      label: value.fullName,
      value: value,
    },
  ];
};

const paginatedUserOptions = (data: Pagination<User>): Option[] =>
  data.results.map((user) => ({
    label: user.fullName,
    value: user,
  }));

const protectionOptions: Option[] = Object.keys(Protection).map(
  (protection) => ({
    label: Protection[protection],
    value: protection,
  }),
);

const protectionOtherOptions: Option[] = Object.keys(ProtectionOther).map(
  (protection) => ({
    label: ProtectionOther[protection],
    value: protection,
  }),
);

const paginatedComponentOptions = (components: Component[]) => {
  return components.reduce((options, component) => {
    const category = component.favorite
      ? 'Favorites'
      : toTitleCase(component.category);
    let group = options.find((option) => option.label === category);
    if (!group) {
      group = {
        label: category,
        options: [],
      };
      if (component.favorite) {
        options.unshift(group);
      } else {
        options.push(group);
      }
    }
    group.options.push({
      label: component.name,
      value: component,
    });

    return options;
  }, []);
};

export {
  choiceFromBoolean,
  choiceToBoolean,
  categoryOptions,
  typeOptions,
  paginatedTypeOptions,
  seriesOptions,
  paginatedSeriesOptions,
  customerOptions,
  paginatedCustomerOptions,
  territoryOptions,
  panelTypeOptions,
  paginatedPanelTypeOptions,
  plateFinishOptions,
  paginatedPlateFinishOptions,
  contactOptions,
  paginatedContactOptions,
  statusOptions,
  userOptions,
  paginatedUserOptions,
  protectionOptions,
  protectionOtherOptions,
  paginatedComponentOptions,
};
