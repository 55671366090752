import { Details, Part } from './parts';
import { Image, Text } from '@bespohk/uikit/components';

import { Panel } from '@app/models/panel';
import { Project } from '@app/models/project';
import * as React from 'react';
import classNames from 'classnames/bind';
import { generate } from '@app/services/panel-set';
import { rehydrate } from '@bespohk/lib';
import styles from './drawings.module.css';
import { Header } from './header';

type OwnProps = {
  className?: string;
};

const cx = classNames.bind(styles);

const panel = rehydrate(Panel, { components: [] });
const project = rehydrate(Project, { customer: {} });
const panelSet = generate(panel, {
  hbars: [],
  wallboxes: [],
  grids: [],
  clampRails: [],
  screws: [],
  popRivets: [],
  strappings: [],
  engravings: [],
  materials: [],
  wirings: [],
  dividers: [],
  data: [],
  panelTypes: [],
  plateFinishes: [],
  routes: [],
  series: [],
  spacers: [],
});

const Cover = ({ className }: OwnProps) => {
  return (
    <div
      className={cx(className, { page: true, break: true, coverWrapper: true })}
    >
      <Header />
      <div className={cx({ drawing: true, cover: true })}>
        <table className={cx({ table: true })}>
          <thead>
            <tr>
              <th colSpan={2}>ALPHAMAX - FLUSH MOUNTED PANEL SYSTEM</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={cx({ typicalNotes: true })}>TYPICAL NOTES</td>
              <td>TYPICAL WALLBOX PROFILE</td>
            </tr>
            <tr>
              <td className={cx({ notes: true })}>
                <Text>
                  Drawings attached are compliant with AS/NZS3003 and AS2896
                </Text>

                <Text>
                  Suitable for Body and Cardiac protected areas as nominated on
                  drawings
                </Text>

                <Text>
                  Panels that are longer than 1180mm will require a H-Bar to
                  join adjoining panel
                </Text>

                <Text>
                  Panels are supplied in either stainless steel or powdercoated
                  as nominated on drawings
                </Text>

                <Text>
                  Customer signature approving each drawing with no changes
                  required prior to manufacturing
                </Text>

                <Text>
                  75mm wall boxes to be used in RCD/AVAM bank panels only
                </Text>

                <Text>ESCO Terms and Conditions of sale apply</Text>
              </td>
              <td className={cx({ explodedView: true })}>
                <Image src={`${process.env.ASSET_BASE}/img/cover-page.png`} />
              </td>
            </tr>
          </tbody>
        </table>
        <Part position="bottom" className={cx('bottom')}>
          <div>&nbsp;</div>
          <Details panelSet={panelSet} project={project} />
        </Part>
      </div>
    </div>
  );
};

export { Cover };
export default Cover;
