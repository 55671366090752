import { State as BaseState } from '../types'; // eslint-disable-line
import { Pagination } from '../types'; // eslint-disable-line

const Types = {
  FETCH_REQUEST: '@@resources/FETCH_REQUEST',
  FETCH_SUCCESS: '@@resources/FETCH_SUCCESS',
  FETCH_ERROR: '@@resources/FETCH_ERROR',
  CLEAR: '@@resources/CLEAR',
};

type State<T> = BaseState<Pagination<T>> & {
  // klass: C;
};

export { Types, State };

export default Types;
