import { Component, Data } from '@app/models/component';

import { get } from '@app/helpers/api';
import { Grid } from '@app/models/grid';
import Hbar from '@app/models/hbar';
import { Wallbox } from '@app/models/wallbox';

import ClampRail from '@app/models/clamp-rail';
import { Screw } from '@app/models/screw';
import { PopRivet } from '@app/models/pop-rivet';
import Strapping from '@app/models/strapping';
import Engraving from '@app/models/engraving';
import Material from '@app/models/material';
import Wiring from '@app/models/wiring';
import Divider from '@app/models/divider';
import PlateFinish from '@app/models/plate-finish';
import PanelType from '@app/models/panel-type';
import Series from '@app/models/series';
import { Route } from '@app/models/route';
import { RouteSetting } from '@app/models/route-setting';

type Lookups = {
  hbars: Hbar[];
  wallboxes: Wallbox[];
  grids: Grid[];
  clampRails: ClampRail[];
  screws: Screw[];
  popRivets: PopRivet[];
  strappings: Strapping[];
  engravings: Engraving[];
  materials: Material[];
  wirings: Wiring[];
  dividers: Divider[];
  routes: Route[];
  spacers: Component[];
  data: Data[];
  plateFinishes: PlateFinish[];
  panelTypes: PanelType[];
  series: Series[];
  routeSettings: RouteSetting[];
};

const fetch = async () => {
  const data = await Promise.all([
    get('/hbars?limit=500', Hbar),
    get('/grids?limit=500', Grid),
    get('/wallboxes?limit=500', Wallbox),
    get('/components?query=(enabled:True,code:spacer){*}&limit=50', Component),
    get('/components/datas?limit=500', Data),
    get('/clamp-rails?query=(enabled:True){*}&limit=500', ClampRail),
    get('/screws?query=(enabled:True){*}&limit=500', Screw),
    get('/pop-rivets?query=(enabled:True){*}&limit=500', PopRivet),
    get('/strappings?query=(enabled:True){*}&limit=500', Strapping),
    get('/engravings?query=(enabled:True){*}&limit=500', Engraving),
    get('/materials?query=(enabled:True){*}&limit=500', Material),
    get('/wirings?query=(enabled:True){*}&limit=500', Wiring),
    get('/dividers?query=(enabled:True){*}&limit=500', Divider),
    get('/plate-finishes?limit=500', PlateFinish),
    get('/panel-types?limit=500', PanelType),
    get('/series?limit=500', Series),
    get('/routes?query=(enabled:True){*}&limit=500', Route),
    get('/route-settings?query=(enabled:True){*}&limit=500', RouteSetting),
  ]);

  const [
    hbars,
    grids,
    wallboxes,
    spacers,
    datas,
    clampRails,
    screws,
    popRivets,
    strappings,
    engravings,
    materials,
    wirings,
    dividers,
    plateFinishes,
    panelTypes,
    series,
    routes,
    routeSettings,
  ] = data;

  const lookups: Lookups = {
    hbars: hbars.results,
    grids: grids.results,
    wallboxes: wallboxes.results,
    clampRails: clampRails.results,
    screws: screws.results,
    popRivets: popRivets.results,
    strappings: strappings.results,
    engravings: engravings.results,
    materials: materials.results,
    wirings: wirings.results,
    dividers: dividers.results,
    routes: routes.results,
    spacers: spacers.results,
    plateFinishes: plateFinishes.results,
    panelTypes: panelTypes.results,
    data: datas.results,
    series: series.results,
    routeSettings: routeSettings.results,
  };

  return lookups;
};

export { fetch };
export type { Lookups };
