import { Project } from './project';
import { formatDate } from '@app/helpers/dates';
import { hydrate } from '@bespohk/lib';

class Approval {
  readonly id: number;
  sent: boolean;
  @hydrate
  project: Project;
  @hydrate
  sendDate: Date;
  @hydrate
  createdDate?: Date;

  public get humanSendAt() {
    return formatDate(this.sendDate);
  }

  public get humanCreatedAt() {
    return formatDate(this.createdDate);
  }
}

export default Approval;
