enum Territory {
  victoria = 'Victoria',
  new_south_wales = 'New South Wales',
  tasmania = 'Tasmania',
  queensland = 'Queensland',
  western_australia = 'Western Australia',
  south_australia = 'South Australia',
  northern_territory = 'Northern Territory',
  australian_capital_territory = 'Australian Capital Territory',
  new_zealand = 'New Zealand',
  xpo = 'XPO',
  ice = 'ICE',
}

const mapTerritoryToCode = (territory: Territory): string => {
  const code: string[] = territory.toLowerCase().split('_');
  if (code[0] === 'queensland') {
    return 'QLD';
  }
  if (code.length < 2) {
    return code[0].substring(0, 3).toUpperCase();
  }

  let mappedTerritory = code.map((c) => c[0].toUpperCase()).join('');
  if (mappedTerritory === 'SA') {
    mappedTerritory = 'SAU';
  } else if (mappedTerritory === 'WA') {
    mappedTerritory = 'WAU';
  } else if (mappedTerritory === 'NT') {
    mappedTerritory = 'NTS';
  }

  return mappedTerritory;
};

export { mapTerritoryToCode };

export default Territory;
