type ReadFile = {
  file: File;
  data: string | ArrayBuffer;
};

const read = (file: File): Promise<ReadFile> => {
  return new Promise((resolve, reject) => {
    const reader: FileReader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onerror = (ev: ProgressEvent) => {
      reject(ev);
    };
    reader.onload = () => {
      const arr: string | ArrayBuffer = reader.result;
      resolve({
        file: file,
        data: arr,
      });
    };
  });
};

const upload = async (data: File[]) => {
  const readFiles = await Promise.all(data.map((file) => read(file)));

  return readFiles;
};

export { upload, ReadFile };
