import {
  Button,
  Field,
  Form,
  Icons,
  Input,
  Pagination,
  Prompt,
  Table,
  type Column,
} from '@bespohk/uikit/components';
import {
  InputSuffixButton,
  NavigationButton,
  FilterBar,
} from '@app/components';
import { operations } from '@app/state/ducks/resources/operations';
import * as React from 'react';
import {
  Operations as ResourceOperations,
  operations as resourceOperations,
} from '@app/state/ducks/resource/operations';
import { download } from '@app/helpers/browser';

import State from '@app/state';
import { createSelector } from 'reselect';
import { useHistory } from 'react-router-dom';
import { useOperations } from '@app/helpers/redux';
import { useSelector } from 'react-redux';

import { logger } from '@app/helpers/log';

const log = logger('containers:tabular-data');

type Action<T> = {
  name: string;
  icon: Icons;
  callback: (item: T) => void;
};

type Filter = {
  placeholder?: string;
  export?: boolean;
  create?: boolean;
  builder?: (q: string) => string;
};

type OwnProps<T> = {
  filter?: Filter | boolean;
  columns: Column<T>[];
  actions?: Action<T>[];
  type: any;
  endpoint: string;
  identifier?: string;
  path?: string;
  query?: string;
  perPage?: number;
};

const resultsSelector = (state: State) => state.resources;

const tabularDataSelector = createSelector(
  resultsSelector,
  (resources) => resources,
);

const columnKeysInData = <T,>(columns: Column<T>[], data: T[]): boolean => {
  if (!data.length) {
    return false;
  }

  return columns.reduce((exists, column) => {
    if (column.key === 'actions') {
      return exists;
    }
    // @ts-ignore FIX: works, but type needs to be resolved
    if (!(column.key in data[0])) {
      exists = false;
    }

    return exists;
  }, true);
};

const TabularData = <T,>({
  filter = {
    placeholder: 'Enter the value to filter by...',
    export: false,
  },
  columns,
  actions,
  type,
  endpoint,
  path,
  identifier = 'uuid',
  perPage = 10,
}: OwnProps<T>) => {
  const { fetch, exportCsv } = useOperations<typeof operations>(operations);
  const { del } = useOperations<ResourceOperations>(resourceOperations);
  const { data, loading } = useSelector(tabularDataSelector);
  const history = useHistory();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [askDelete, setAskDelete] = React.useState<T>();
  if (!columnKeysInData<T>(columns, data.results as T[])) {
    // Required due to shared resources duck, lifecycle doesn't apply
    // switching between pages causes the table to be populated with pre-existing
    // data as the resources data is a single duck.
    // Requires that the keys for the columns DO exist on the data
    log('Columns to be displayed do not match the requested item');
    data.results = [];
  }

  const { builder } = filter as Filter;

  const generateEndpoint = (filterString?: string) => {
    const builtQuery = builder ? builder(filterString) : undefined;
    const url = `${endpoint}?query=${builtQuery || '{*}'}`;

    return url;
  };

  React.useEffect(() => {
    fetch(type, generateEndpoint(), 0, perPage);

    return () => {
      setCurrentPage(0);
    };
  }, [endpoint]);

  const pagination = (filterText: string) => {
    const url = generateEndpoint(filterText);

    return (
      <Pagination
        total={data.count}
        current={currentPage}
        maxItems={perPage}
        maxPages={2}
        useIcons
        onChangePage={(page) => {
          if (page === currentPage) {
            return;
          }
          setCurrentPage(page);
          fetch(type, url, (page - 1) * perPage, perPage);
        }}
      />
    );
  };
  const pathOrEndpoint: string = path || endpoint;
  const defaultActions: Action<T>[] = [
    {
      name: 'Edit',
      icon: Icons.Edit,
      callback: (item) => history.push(`${pathOrEndpoint}/${item[identifier]}`),
    },
    {
      name: 'Delete',
      icon: Icons.Trash,
      callback: (item) => setAskDelete(item),
    },
  ];

  const combinedActions: Action<T>[] = [...defaultActions, ...(actions || [])];

  if (!columns.find((column) => column.key === 'actions')) {
    columns.push({
      key: 'actions' as any,
      label: ' ',
      width: '15%',
      renderer: (d) => {
        return (
          <>
            {combinedActions
              .filter((action) => {
                if (
                  action.name === 'Delete' &&
                  columns.find((column) => column.key === 'isActive') &&
                  !d['isActive']
                ) {
                  return false;
                }

                return true;
              })
              .map((action) => (
                <NavigationButton
                  key={action.name}
                  size="small"
                  icon={action.icon}
                  label={action.name}
                  path={() => action.callback(d)}
                />
              ))}
          </>
        );
      },
    });
  }

  return (
    <>
      <Form<{ filter: any }>
        onSubmit={(values) => {
          fetch(type, generateEndpoint(values.filter));
        }}
      >
        {({ values }) => {
          return (
            <>
              <FilterBar>
                <Field label={null}>
                  <Input
                    name="filter"
                    placeholder={filter ? (filter as Filter).placeholder : null}
                    autoFocus
                    prefix="Filter"
                    suffix={<InputSuffixButton icon={Icons.Search} />}
                  />
                </Field>
                {!!filter && (
                  <>
                    {(filter as Filter).create !== false && (
                      <Button
                        label="Create"
                        action={() => history.push(`${endpoint}/new`)}
                      />
                    )}
                    {(filter as Filter).export && (
                      <Button
                        label="Export"
                        style="tertiary"
                        action={async () => {
                          const output = await exportCsv(endpoint);
                          if (output) {
                            download(
                              output,
                              `${type.name.toLowerCase()}-export-${Date.now()}.csv`,
                              'text/csv',
                            );
                          }
                        }}
                      />
                    )}
                  </>
                )}
              </FilterBar>
              {pagination(values.filter)}
              <Table<T>
                loading={loading}
                columns={columns}
                data={data.results as T[]}
              />
              {pagination(values.filter)}
            </>
          );
        }}
      </Form>
      <Prompt
        title="Delete?"
        message="Are you sure you want to delete this record?"
        when={!!askDelete}
        positive={{
          label: 'Yes',
          action: async () => {
            await del(`${endpoint}/${askDelete[identifier]}`);
            fetch(type, generateEndpoint(), currentPage, perPage);
            setAskDelete(null);
          },
        }}
        negative={{
          label: 'No',
          action: () => setAskDelete(null),
        }}
      />
    </>
  );
};

export { TabularData };
export default TabularData;
