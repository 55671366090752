import { PanelSet } from './panel-set';
import PlateFinish from './plate-finish';
import { Project } from './project';

type SupplyMethod = {
  type: string;
  partNumber: string;
  price: number;
  priceTotal: number;
};

type ComponentCounts = {
  [code: string]: number;
};

type Line = {
  customerReference: string;
  quantity: number;
  supplyMethod: SupplyMethod[];
  componentCounts: ComponentCounts;
};

type Categories = {
  [category: string]: {
    [code: string]: string[];
  };
};

class Inclusion {
  project: Project;

  public get name(): string {
    return this.project.name;
  }

  public get drawingReference(): string {
    return this.project.drawingReference;
  }

  public get revision(): number {
    return this.project.revision;
  }

  public get totalCost(): number {
    return this.project.netCost;
  }

  public get notes(): string[] {
    const specifiedNotes: string[] = [];
    let gpoCentre: number = null;
    let plateFinish: PlateFinish = null;
    const { activePanelSets: panelSets, notes } = this.project;

    const firstPanelSet: PanelSet = panelSets.length ? panelSets[0] : null;
    if (!firstPanelSet) {
      if (notes) {
        specifiedNotes.push(notes);
      }

      return specifiedNotes;
    }

    const { panel } = firstPanelSet;

    gpoCentre = panel.gpoCentre;
    plateFinish = panel.plateFinish;
    specifiedNotes.push(
      `Plate Finish - ${plateFinish.name} - all panels unless specified below`,
    );
    specifiedNotes.push(
      `GPO Centres - ${gpoCentre}mm - all panels unless specified below`,
    );

    if (notes) {
      specifiedNotes.push(notes);
      specifiedNotes.push('');
    }

    this.project.panelSets.forEach((panelSet) => {
      const { panel: panel_ } = panelSet;
      if (panel_.deleted) {
        return;
      }

      const panelNotes: string[] = [];
      if (panel_.specialNotes) {
        panelNotes.push(panel_.specialNotes);
      }

      if (plateFinish.id !== panel_.plateFinish.id) {
        panelNotes.push(`Plate Finish ${panel_.plateFinish.name}`);
      }

      if (gpoCentre !== panel_.gpoCentre) {
        panelNotes.push(`GPO Centres ${panel_.gpoCentre}mm`);
      }

      if (panelNotes.length) {
        const joinedNotes = panelNotes.join(', ');
        specifiedNotes.push(`${panel_.mspReference}: ${joinedNotes}`);
      }
    });

    return specifiedNotes;
  }

  public get categories(): Categories {
    const categories: Categories = {};
    const { activePanelSets: panelSets } = this.project;

    panelSets.forEach((panelSet) => {
      const { panel } = panelSet;
      panel.components.forEach((component) => {
        const { equipmentCode, isSpacer, category, legendDescription } =
          component;
        if (isSpacer) {
          return;
        }
        const upperCategory = category.toUpperCase();
        if (!categories[upperCategory]) {
          categories[upperCategory] = {};
        }
        if (!categories[upperCategory][equipmentCode]) {
          categories[upperCategory][equipmentCode] = [];
        }

        const code = categories[upperCategory][equipmentCode];
        if (code.indexOf(legendDescription) < 0) {
          code.push(legendDescription);
        }
      });
    });

    return Object.keys(categories)
      .sort()
      .reduce((cats, cat) => {
        cats[cat] = categories[cat];

        return cats;
      }, {});
  }

  public get lines(): Line[] {
    const { activePanelSets: panelSets } = this.project;

    return panelSets.reduce((lines: Line[], panelSet) => {
      const { panel, backplate } = panelSet;
      const { mspReference, quantity, components } = panel;

      const counts: ComponentCounts = {};
      const categories = this.categories;
      Object.keys(categories).forEach((category) => {
        Object.keys(categories[category]).forEach((equipmentCode) => {
          if (!counts[equipmentCode]) {
            counts[equipmentCode] = 0;
          }
        });
      });

      components.forEach((component) => {
        if (component.isSpacer) {
          return;
        }
        counts[component.equipmentCode] += component.quantity;
      });

      const {
        panelSet: panelSetCosts,
        backplate: backplateCosts,
        panel: panelCosts,
      } = panelSet.discountedTotalCosts;

      lines.push({
        customerReference: mspReference,
        quantity,
        supplyMethod: [
          {
            type: 'Panel Set',
            partNumber: panelSet.panelPartNumber,
            price: panelCosts.unit,
            priceTotal: panelCosts.total,
          },
          {
            type: 'Wall Box',
            partNumber: backplate.partNumber,
            price: backplateCosts.unit,
            priceTotal: backplateCosts.total,
          },
          {
            type: '',
            partNumber: panel.panelType.name,
            price: panelSetCosts.unit,
            priceTotal: panelSetCosts.total,
          },
        ],
        componentCounts: counts,
      });

      return lines;
    }, []);
  }

  public get totalMsp(): number {
    return this.project.activePanelSets.reduce((p, c) => {
      return p + c.panel.quantity;
    }, 0);
  }
}

export { Line };

export default Inclusion;
