import { toTitleCase } from '@bespohk/lib';

class Screw {
  description: string;
  partNumber: string;
  enabled: boolean;
  type: 'front_plate' | 'wallbox';

  public get humanType(): string {
    return toTitleCase(this.type);
  }

  public toString(): string {
    return this.description;
  }
}

export default Screw;
export { Screw };
