import * as yup from 'yup';

import { Choice, Input } from '@bespohk/uikit/components';

import { default as Model } from '@app/models/user';
import { ModelForm } from '@app/containers';
import * as React from 'react';
import { choiceToBoolean } from '@app/helpers/form';

type OwnProps = unknown;
const User = (_: OwnProps) => {
  return (
    <ModelForm
      identifier="uuid"
      endpoint="/users"
      type={Model}
      initialTransform={yup.object().shape({
        uuid: yup.string().strip(true),
        createdDate: yup.mixed().strip(true),
        updatedDate: yup.mixed().strip(true),
      })}
      preValidateTransform={yup.object().shape({
        enabled: choiceToBoolean(),
      })}
      validate={yup.object().shape({
        firstName: yup.string().required('First Name is a required field.'),
        lastName: yup.string().required('Last Name is a required field.'),
        email: yup.string().required('Email is a required field.'),
        title: yup.string().required('Title is a required field.'),
        roles: yup.array().required('At least one role is required.'),
      })}
      fieldsets={[
        [
          {
            name: 'email',
            renderer: Input,
            props: { type: 'email', required: true },
          },
          { name: 'password', renderer: Input, props: { type: 'password' } },
        ],
        [
          { name: 'firstName', renderer: Input, props: { required: true } },
          { name: 'lastName', renderer: Input, props: { required: true } },
        ],
        [{ name: 'title', renderer: Input, props: { required: true } }, null],
        [{ name: 'code', renderer: Input }, null],
        [
          {
            name: 'roles',
            renderer: Choice,
            props: {
              required: true,
              options: Object.keys(Model.roleMapping).map((key) => ({
                value: key,
                label: Model.roleMapping[key],
              })),
            },
          },
        ],
      ]}
    />
  );
};

export { User };
export default User;
