class Series {
  readonly id: number;
  name: string;
  gpoCentres: number[];

  public toString(): string {
    return this.name;
  }
}

export default Series;
