import { Image } from '@bespohk/uikit/components';
import { Panel } from '@app/models/panel';
import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './parts.module.css';
import PanelType from '@app/models/panel-type';

const cx = classNames.bind(styles);

const panelTypeLabel = (panelType: PanelType): string | null => {
  const name = panelType.name.toLowerCase();

  if (name.includes('mri')) {
    return 'MRI Compatible';
  }
  if (name.includes('sealed')) {
    return 'Sealed Panel';
  }

  return null;
};

type OwnProps = {
  panel: Panel;
};

const BodyProtected = ({ panel }: OwnProps) => {
  const type: string =
    panel.protectionOther.toString() === 'body_protected' ? 'body' : 'cardiac';
  const label = panelTypeLabel(panel.panelType);

  return (
    <>
      <Image
        className={cx({ protection: true })}
        src={`${process.env.ASSET_BASE}/img/${type}.svg`}
      />
      {label && <span className={cx('label')}>{label}</span>}
    </>
  );
};

export { BodyProtected };
export default BodyProtected;
