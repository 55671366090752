import * as yup from 'yup';

import { Screw as Model } from '@app/models/screw';
import { Input, Select } from '@bespohk/uikit/components';
import { ModelForm } from '@app/containers';
import * as React from 'react';
import { Choice } from '@bespohk/uikit';
import { choiceFromBoolean, choiceToBoolean } from '@app/helpers/form';

type OwnProps = unknown;

const Screw = (_: OwnProps) => {
  return (
    <ModelForm
      identifier="id"
      endpoint="/screws"
      type={Model}
      validate={yup.object().shape({
        partNumber: yup.string().required('Part Number is a required field.'),
        description: yup.string().required('Description is a required field.'),
      })}
      initialTransform={yup.object().shape({
        enabled: choiceFromBoolean(),
        type: yup.mixed().convert('.'),
      })}
      preValidateTransform={yup.object().shape({
        enabled: choiceToBoolean(),
        type: yup.mixed().convert('.'),
      })}
      fieldsets={[
        [
          {
            name: 'partNumber',
            renderer: Input,
            props: { required: true },
          },
        ],
        [
          {
            name: 'description',
            renderer: Input,
            props: { required: true },
          },
        ],
        [
          {
            name: 'type',
            renderer: Select,
            props: {
              options: [
                { value: 'front_plate', label: 'Front Plate' },
                { value: 'wallbox', label: 'Wallbox' },
              ],
              openOnFocus: true,
              required: true,
            },
          },
        ],
        [
          {
            name: 'enabled',
            label: null,
            renderer: Choice,
            props: {
              options: [{ value: true, label: 'Enabled' }],
            },
          },
        ],
      ]}
    />
  );
};

export { Screw };
export default Screw;
