import * as yup from 'yup';

import { PopRivet as Model } from '@app/models/pop-rivet';
import { Input } from '@bespohk/uikit/components';
import { ModelForm } from '@app/containers';
import * as React from 'react';
import { Choice } from '@bespohk/uikit';
import { choiceFromBoolean, choiceToBoolean } from '@app/helpers/form';

type OwnProps = unknown;

const PopRivet = (_: OwnProps) => {
  return (
    <ModelForm
      identifier="id"
      endpoint="/pop-rivets"
      type={Model}
      validate={yup.object().shape({
        partNumber: yup.string().required('Part Number is a required field.'),
        description: yup.string().required('Description is a required field.'),
      })}
      initialTransform={yup.object().shape({
        enabled: choiceFromBoolean(),
      })}
      preValidateTransform={yup.object().shape({
        enabled: choiceToBoolean(),
      })}
      fieldsets={[
        [
          {
            name: 'partNumber',
            renderer: Input,
            props: { required: true },
          },
        ],
        [
          {
            name: 'description',
            renderer: Input,
            props: { required: true },
          },
        ],
        [
          {
            name: 'enabled',
            label: null,
            renderer: Choice,
            props: {
              options: [{ value: true, label: 'Enabled' }],
            },
          },
        ],
      ]}
    />
  );
};

export { PopRivet };
export default PopRivet;
