import { Button, Icon, Icons, useTheme } from '@bespohk/uikit/components';

import * as React from 'react';
import styles from './input-suffix-button.module.css';

type OwnProps = {
  icon: Icons;
};

const InputSuffixButton = ({ icon }: OwnProps) => {
  const cx = useTheme(Button, styles);

  return (
    <Button
      className={cx({ outer: true })}
      style="custom"
      type="submit"
      label={<Icon name={icon} className={cx({ icon: true })} />}
    />
  );
};

export { InputSuffixButton };
export default InputSuffixButton;
