import { Button, Heading, Text } from '@bespohk/uikit/components';

import { Content } from '../content'; // eslint-disable-line
import * as React from 'react';

type OwnProps = {
  children: React.ReactElement<unknown>;
};

type OwnState = {
  error?: Error;
};

const JSError = () => {
  return (
    <Content>
      <Heading size="large">Something has gone wrong!</Heading>
      <Text>
        This issue has been logged, and administrators have been notified.
      </Text>
      <Text>
        <Button type="text" label="Return" action="/" /> to the home page.
      </Text>
    </Content>
  );
};

// Hooks dont support componentDidCatch just yet...
class Boundry extends React.Component<OwnProps, OwnState> {
  state = {
    error: null,
  } as OwnState;

  componentDidCatch(error: Error) {
    const { Sentry } = window as any; // eslint-disable-line
    if (Sentry) {
      Sentry.captureException(error);
    }
    this.setState({ error });
  }

  render(): React.ReactElement<unknown> {
    if (this.state.error) {
      return <JSError />;
    }

    return this.props.children;
  }
}

export { Boundry };
export default Boundry;
