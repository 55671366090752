import Action from '../types'; // eslint-disable-line
import Token from '@app/models/token';
import { Types } from './types';
import User from '@app/models/user';

const loginAttempt = (): Action<any> => ({
  type: Types.LOGIN_REQUEST,
  payload: null,
});

const loginSuccess = (
  token: Token,
  user: User,
): Action<{ token: Token; user: User }> => ({
  type: Types.LOGIN_SUCCESS,
  payload: {
    token,
    user,
  },
});

const loginFailure = (): Action<any> => ({
  type: Types.LOGIN_FAILURE,
  payload: null,
});

const logoutAttempt = (): Action<any> => ({
  type: Types.LOGOUT_REQUEST,
  payload: null,
});

const logoutSuccess = (): Action<any> => ({
  type: Types.LOGOUT_SUCCESS,
  payload: null,
});

const logoutFailure = (): Action<any> => ({
  type: Types.LOGOUT_FAILURE,
  payload: null,
});

export {
  loginAttempt,
  loginSuccess,
  loginFailure,
  logoutAttempt,
  logoutSuccess,
  logoutFailure,
};
