import { Alert, Message, Type } from '@app/models/alert';
import { add as addAction, remove as removeAction } from './actions';

import { Dispatch } from 'redux';

const add =
  (type: Type, message: Message, delay = 0): any =>
  (dispatch: Dispatch<any>) => {
    const alert: Alert = { type, message, delay };
    dispatch(addAction(alert));
    if (delay) {
      setTimeout(() => {
        dispatch(removeAction(alert));
      }, delay * 1000);
    }
  };

const remove =
  (alert: Alert): any =>
  (dispatch: Dispatch<any>) => {
    dispatch(removeAction(alert));
  };

type Operations = {
  add: (type: Type, message: Message, delay?: number) => Dispatch<any>;
  remove: (alert: Alert) => Dispatch<any>;
};

const operations: Operations = {
  add,
  remove,
};

export { operations, Operations };
