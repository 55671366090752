import { Action as BaseAction } from '../types'; // eslint-disable-line
import { Panel } from '@app/models/panel';
import { Offering, Project } from '@app/models/project';
import { PanelSet } from '@app/models/panel-set';
import { Types } from './types';

type Action<T = any> = BaseAction<Project> & {
  data?: T;
};

type UndeleteAction = Action<Panel>;

type ApproveAction = Action<{ panel: Panel; type: any; valid: boolean }>;

// Modify the return types for each action
const createRequest = (): any => {
  return {
    type: Types.CREATE_REQUEST,
  };
};

const createSuccess = (project: Project): Action => {
  return {
    type: Types.CREATE_SUCCESS,
    payload: project,
  };
};

const createError = (): any => {
  return {
    type: Types.CREATE_ERROR,
  };
};

const fetchRequest = (): Action => ({
  type: Types.FETCH_REQUEST,
  loading: true,
  payload: null,
});

const fetchSuccess = (project: Project): Action => ({
  type: Types.FETCH_SUCCESS,
  loading: false,
  payload: project,
});

const fetchError = (): Action => ({
  type: Types.FETCH_ERROR,
  loading: false,
  payload: null,
});

const updateRequest = (): any => {
  return {
    type: Types.UPDATE_REQUEST,
  };
};

const updateSuccess = (project: Project): Action => {
  return {
    type: Types.UPDATE_SUCCESS,
    payload: project,
  };
};

const updateError = (): any => {
  return {
    type: Types.UPDATE_ERROR,
  };
};

const deleteRequest = (): any => {
  return {
    type: Types.DELETE_REQUEST,
  };
};

const deleteSuccess = (): any => {
  return {
    type: Types.DELETE_SUCCESS,
  };
};

const deleteError = (): any => {
  return {
    type: Types.DELETE_ERROR,
  };
};

const clear = (): any => ({
  type: Types.CLEAR,
});

const addNewPanel = (
  project: Project,
  panel: Panel,
  panelSet: PanelSet,
): Action => ({
  type: Types.ADD_NEW_PANEL,
  payload: project,
  data: { panel, panelSet },
});

const deletePanel = (project: Project, panel: Panel): Action => ({
  type: Types.DELETE_PANEL,
  payload: project,
  data: panel,
});

const createRevisionRequest = (project: Project, _: Panel): Action => ({
  type: Types.CREATE_REVISION_REQUEST,
  payload: project,
});

const createRevisionSuccess = (project: Project, panel: Panel): Action => ({
  type: Types.CREATE_REVISION_SUCCESS,
  payload: project,
  data: panel,
});

const createRevisionError = (project: Project, panel: Panel): Action => ({
  type: Types.CREATE_REVISION_ERROR,
  payload: project,
  data: panel,
});

const duplicateRequest = (_: Project): Action => ({
  type: Types.DUPLICATE_REQUEST,
  payload: null,
});

const duplicateSuccess = (project: Project): Action => ({
  type: Types.DUPLICATE_SUCCESS,
  payload: project,
});

const duplicateError = (project: Project): Action => ({
  type: Types.DUPLICATE_ERROR,
  payload: project,
});

const setOfferings = (project: Project, offerings: Offering[]): Action => ({
  type: Types.SET_OFFERINGS,
  payload: project,
  data: offerings,
});

const undeleteRequest = (project: Project, panel: Panel): UndeleteAction => ({
  type: Types.UNDELETE_PANEL_REQUEST,
  payload: project,
  data: panel,
});

const undeleteSuccess = (project: Project, panel: Panel): UndeleteAction => ({
  type: Types.UNDELETE_PANEL_SUCCESS,
  payload: project,
  data: panel,
});

const approveRequest = (
  project: Project,
  panel: Panel,
  type: any,
  valid: boolean,
): ApproveAction => ({
  type: Types.APPROVE_PANEL_REQUEST,
  payload: project,
  data: { panel, type, valid },
});

const approveSuccess = (
  project: Project,
  panel: Panel,
  type: any,
  valid: boolean,
): ApproveAction => ({
  type: Types.APPROVE_PANEL_SUCCESS,
  payload: project,
  data: { panel, type, valid },
});

const createTestRequest = (): Action => ({
  type: Types.CREATE_TEST_REQUEST,
  payload: undefined,
});

const createTestSuccess = (project: Project): Action => ({
  type: Types.CREATE_TEST_SUCCESS,
  payload: project,
});

const createTestError = (): Action => ({
  type: Types.CREATE_TEST_ERROR,
  payload: undefined,
});

export {
  Action,
  createRequest,
  createSuccess,
  createError,
  fetchRequest,
  fetchSuccess,
  fetchError,
  updateRequest,
  updateSuccess,
  updateError,
  deleteRequest,
  deleteSuccess,
  deleteError,
  clear,
  addNewPanel,
  deletePanel,
  createRevisionError,
  createRevisionRequest,
  createRevisionSuccess,
  duplicateRequest,
  duplicateError,
  duplicateSuccess,
  setOfferings,
  UndeleteAction,
  undeleteRequest,
  undeleteSuccess,
  approveRequest,
  approveSuccess,
  createTestRequest,
  createTestSuccess,
  createTestError,
};
