import Inclusion from '@app/models/inclusion';
import { Project } from '@app/models/project';

/**
 * Generates an inclusion from a project
 *
 * @param project A generated project from services/project.ts
 */
const generate = (project: Project): Inclusion => {
  const inclusion: Inclusion = new Inclusion();
  inclusion.project = project;

  return inclusion;
};

export { generate };
