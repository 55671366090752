import Hbar from '@app/models/hbar';
import * as React from 'react';
import { TabularData } from '@app/containers';

const Hbars = () => (
  <TabularData
    type={Hbar}
    endpoint="/hbars"
    columns={[{ key: 'description', label: 'Name' }, { key: 'partNumber' }]}
    filter={{
      placeholder: 'Enter a part number or description to search for...',
      builder: (q: string) => (q ? `(name: ${q}){*}` : undefined),
      export: true,
    }}
    identifier="id"
  />
);

export { Hbars };
export default Hbars;
