import { default as Model } from '@app/models/backplate';
import { Wallbox } from './wallbox';
import { gridRenderer } from '../helpers'; // eslint-disable-line
import styles from './backplate.module.css';

type OwnProps = {
  backplate: Model;
  showCosts?: boolean;
  showRoutes?: boolean;
  isDrawing?: boolean;
};

const Backplate = ({ backplate, showCosts, isDrawing, showRoutes }: OwnProps) =>
  gridRenderer(backplate.wallboxes, Wallbox, 'wallbox', styles.rows, {
    showCosts,
    showRoutes,
    isDrawing,
  });

export { Backplate };
export default Backplate;
