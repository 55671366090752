import { default as BaseAction, Pagination } from '../types'; // eslint-disable-line

import { Types } from './types';

type Action<T> = BaseAction<Pagination<T>> & {
  // klass: C;
};

const fetch = (): Action<[]> => ({
  type: Types.FETCH_REQUEST,
  payload: {
    count: 0,
    results: [],
  },
  // klass: null,
});

const fetchSuccess = <T>(payload: Pagination<T>): Action<T> => ({
  type: Types.FETCH_SUCCESS,
  payload,
});

const fetchError = (): Action<[]> => ({
  type: Types.FETCH_ERROR,
  payload: {
    count: 0,
    results: [],
  },
});

const clear = (): Action<[]> => ({
  type: Types.CLEAR,
  payload: {
    count: 0,
    results: [],
  },
});

export { fetch, fetchSuccess, fetchError, clear, Action };
