import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './inclusions.module.css';

const cx = classNames.bind(styles);

type OwnProps = {
  className?: string;
  children: any;
};

const Table = ({ children, className }: OwnProps) => {
  return (
    <table className={cx({ table: true, [className]: !!className })}>
      {children}
    </table>
  );
};

export { Table };
export default Table;
