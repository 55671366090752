import { Timestamps, mixin } from './common';

import Series from './series';
import ToolType from './tool-type';
import Type from './type';
import { formatter } from '@app/helpers/currency';
import { hydrate } from '@bespohk/lib';

enum Category {
  avam = 'AVAM',
  data = 'Data',
  blank = 'Blank',
  earth = 'Earth',
  gpo = 'GPO',
  provision = 'Provision',
  switch = 'Switch',
  rcd = 'RCD',
  ups = 'UPS',
  strapping = 'Strapping',
}

const sizes = [25, 50, 75, 100, 125, 150, 200, 225, 250, 300];

type Overrides = {
  showHbar?: boolean;
  showEngravedLineBefore?: boolean;
  showEngravedLineTop?: boolean;
  forcedWallboxEnd?: boolean;
  forcedDivider?: boolean;
  data?: string;
  rowStart?: boolean;
  gridSize?: number;
};

class Definition {
  readonly uuid: string;
  description: string;
  shortDescription: string;
  legendDescription: string;
  descriptionCharacter: string;
  code: string;
  equipmentCode: string;
  quantity: number;
  category: Category;
  @hydrate
  type: Type;
  @hydrate(Series)
  series: Series[];
  cost: number;
  enabled: boolean;
  isOwnWallbox?: boolean;
  favorite?: boolean;
  width: number;
  labourTime: number;
  bomPartNumber?: string;
  bomDescription?: string;
  punchCode?: string;
  labelOffset?: string;
  psRouteAllocation: number;
  @hydrate(ToolType)
  toolTypes: ToolType[];
  verticalPunchPositions: string;
  horizontalPunchPositions: string;
  screwCount: string;

  @hydrate
  createdDate?: Date;
  @hydrate
  updatedDate?: Date;

  public get humanCost() {
    return formatter.format(this.cost);
  }

  public get availableGridSizes(): number[] {
    return sizes.reduce((sz, c) => {
      const size = this[`gridCount${c}`];
      if (size) {
        sz.push(c);
      }

      return sz;
    }, []);
  }

  public get name(): string {
    return `${this.shortDescription} x ${this.quantity}`;
  }

  // Convenience methods

  public get isSpacer(): boolean {
    return this.code.toLowerCase().indexOf('spacer') > -1;
  }

  public isCategory(category: Category): boolean {
    return this.category.toLowerCase() === category.toLowerCase();
  }

  public isType(type: string): boolean {
    return this.type.name.toLowerCase() === type.toLowerCase();
  }

  public get isGas(): boolean {
    return this.isType('gas');
  }

  public get isElv(): boolean {
    return this.isType('elv');
  }

  public get isGpo(): boolean {
    return this.isCategory(Category.gpo);
  }

  public get isAvam(): boolean {
    return this.isCategory(Category.avam);
  }

  public get isRcd(): boolean {
    return this.isCategory(Category.rcd);
  }

  public get circuit(): string {
    return this.equipmentCode.substr(0, 2);
  }

  public get isUsb(): boolean {
    return this.shortDescription.toLowerCase().indexOf('usb ') > -1;
  }

  public get isNurseCall(): boolean {
    return this.shortDescription.toLowerCase().indexOf('nc ') === 0;
  }

  public get isOwnGrid(): boolean {
    return this.isNurseCall || this.isGpo;
  }

  public get isScav(): boolean {
    return (
      ['PBG2', 'PEBG2', 'PEG2', 'PMG2', 'GAS2P'].indexOf(
        this.equipmentCode.toUpperCase(),
      ) > -1
    );
  }

  public get svg(): string {
    return `${this.equipmentCode}-${this.quantity}-${this.width}.svg`; // ?${Date.now()}
  }

  public toString(): string {
    return this.name;
  }
}

mixin(Definition, [Timestamps]);

interface Definition extends Timestamps {} // eslint-disable-line @typescript-eslint/no-empty-interface

const DEFAULT_GRID_SIZE = 50;

class Component extends Definition {
  // Panel overrides
  showHbar: boolean;
  forcedWallboxEnd: boolean;
  forcedDivider: boolean;
  rowStart: boolean;
  data?: string;
  private _gpoCentre: number;

  // Rendering override
  showEngravedLine: boolean;
  showEngravedLineBefore: boolean;
  showEngravedLineTop: boolean;
  fixed: boolean;

  public get startsRow(): boolean {
    return this.rowStart;
  }

  public get gpoCentre(): number {
    if (!this._gpoCentre) {
      return DEFAULT_GRID_SIZE;
    }

    return this._gpoCentre;
  }

  public set gpoCentre(center: number) {
    this._gpoCentre = center;
  }

  public get hasSvg(): boolean {
    return !this.isSpacer;
  }

  public get svg(): string {
    return `${this.equipmentCode}-${this.quantity}-${this.width}.svg`; // ?${Date.now()}
  }

  public get offset(): { x: number; y: number } {
    if (!this.labelOffset) {
      return { x: 0, y: 0 };
    }

    const [x, y] = this.labelOffset.split(',');

    return { x: parseInt(x), y: parseInt(y) };
  }

  public get componentData(): string[] {
    if (!this.data) {
      return null;
    }

    return this.data.split(',');
  }

  public get humanData(): string {
    if (this.data) {
      return this.data.split(',').join(', ');
    }

    return null;
  }

  public reverseData() {
    if (!this.data) {
      return;
    }

    const data = this.componentData;

    this.data = data.reverse().join(',');
  }

  public get vertPunchPositions(): string[] {
    return this.verticalPunchPositions
      ? this.verticalPunchPositions
          .split(',')
          .map((position) => position.trim())
      : [];
  }

  public get horPunchPositions(): number {
    return this.horizontalPunchPositions
      ? parseInt(this.horizontalPunchPositions)
      : 1;
  }
}

const matchCase = (s1: string, s2: string): boolean =>
  s1.toLowerCase() === s2.toLowerCase();

class Data {
  readonly id: number;
  typeOrEquipmentCode: string;
  // Comma separated
  options: string;

  public matches(component: Component): boolean {
    return (
      matchCase(component.equipmentCode, this.typeOrEquipmentCode) ||
      matchCase(component.type.name, this.typeOrEquipmentCode)
    );
  }

  public get splitOptions(): string[] {
    return this.options.split(',').map((option) => option.trim());
  }

  public toString(): string {
    return this.typeOrEquipmentCode;
  }
}

export { Component, Data, Category, Overrides, Definition };
export default Component;
