const Types = {
  CREATE_REQUEST: '@@project/CREATE_REQUEST',
  CREATE_SUCCESS: '@@project/CREATE_SUCCESS',
  CREATE_ERROR: '@@project/CREATE_ERROR',
  FETCH_REQUEST: '@@project/FETCH_REQUEST',
  FETCH_SUCCESS: '@@project/FETCH_SUCCESS',
  FETCH_ERROR: '@@project/FETCH_ERROR',
  UPDATE_REQUEST: '@@project/UPDATE_REQUEST',
  UPDATE_SUCCESS: '@@project/UPDATE_SUCCESS',
  UPDATE_ERROR: '@@project/UPDATE_ERROR',
  DELETE_REQUEST: '@@project/DELETE_REQUEST',
  DELETE_SUCCESS: '@@project/DELETE_SUCCESS',
  DELETE_ERROR: '@@project/DELETE_ERROR',
  CLEAR: '@@project/CLEAR',
  ADD_NEW_PANEL: '@@project/ADD_NEW_PANEL',
  DELETE_PANEL: '@@project/DELETE_PANEL',
  CREATE_REVISION_REQUEST: '@@project/CREATE_REVISION_REQUEST',
  CREATE_REVISION_SUCCESS: '@@project/CREATE_REVISION_SUCCESS',
  CREATE_REVISION_ERROR: '@@project/CREATE_REVISION_ERROR',
  DUPLICATE_REQUEST: '@@project/DUPLICATE_REQUEST',
  DUPLICATE_SUCCESS: '@@project/DUPLICATE_SUCCESS',
  DUPLICATE_ERROR: '@@project/DUPLICATE_ERROR',
  SET_OFFERINGS: '@@project/SET_OFFERINGS',
  UNDELETE_PANEL_REQUEST: '@@project/UNDELETE_PANEL_REQUEST',
  UNDELETE_PANEL_SUCCESS: '@@project/UNDELETE_PANEL_SUCCESS',
  UNDELETE_PANEL_ERROR: '@@project/UNDELETE_PANEL_ERROR',
  APPROVE_PANEL_REQUEST: '@@project/APPROVE_PANEL_REQUEST',
  APPROVE_PANEL_SUCCESS: '@@project/APPROVE_PANEL_SUCCESS',
  CREATE_TEST_REQUEST: '@@project/CREATE_TEST_REQUEST',
  CREATE_TEST_SUCCESS: '@@project/CREATE_TEST_SUCCESS',
  CREATE_TEST_ERROR: '@@project/CREATE_TEST_ERROR',
};

export { Types };

export default Types;
