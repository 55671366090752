import { operations } from '@app/state/ducks/panel-builder/operations';
import * as React from 'react';

import { Input } from '@bespohk/uikit/components';
import { Panel } from '@app/models/panel';
import { Project } from '@app/models/project';
import styles from './overview.module.css';
import { useOperations } from '@app/helpers/redux';
import { useLookups } from '@app/helpers/hooks';

type OwnProps = {
  panel: Panel;
  project: Project;
  readonly?: boolean;
};

const Discount = ({ panel, project, readonly }: OwnProps) => {
  const [discount, setDiscount] = React.useState(
    panel.discount ? panel.discount : '0',
  );

  const lookups = useLookups();

  const { updateDiscount } = useOperations(operations);

  return (
    <Input
      type="number"
      className={styles.discount}
      name="discount"
      disabled={readonly}
      value={discount}
      onBlur={(name, value) => {
        updateDiscount(project, panel, parseFloat(value), lookups);
      }}
      onChange={(name, value) => {
        if (!value) {
          value = 0;
        }
        setDiscount(parseFloat(value));
      }}
    />
  );
};

export { Discount };
export default Discount;
