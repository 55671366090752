import { Divider } from './divider';
import { Wallbox as Model } from '@app/models/wallbox';
import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './backplate.module.css';
import { Costs } from '../costs'; // eslint-disable-line
import Route from '../route'; // eslint-disable-line

const cx = classNames.bind(styles);

type OwnProps = {
  wallbox: Model;
  showCosts?: boolean;
  showRoutes?: boolean;
  isDrawing?: boolean;
};

const Wallbox = ({ wallbox, showCosts, isDrawing, showRoutes }: OwnProps) => {
  const [date] = React.useState(Date.now());
  const style: React.CSSProperties = {
    width: `${wallbox.width}px`,
  };
  if (wallbox.isValid) {
    style.backgroundImage = `url("${process.env.ASSET_BASE}/img/components/wallboxes/${wallbox.svg}?d=${date}")`;
  }

  return (
    <>
      <div
        className={cx({ wallbox: true, rcd: wallbox.rcdBank, isDrawing })}
        style={style}
      >
        {wallbox.dividers.map((position) => (
          <Divider key={position} position={position} />
        ))}
        <div className={cx({ gang: true })}>
          {wallbox.humanGang}
          <Costs cost={showCosts ? wallbox.humanCost : null} />
          {showRoutes && <Route count={1} />}
        </div>
        <div className={cx({ dimensions: true })}>{wallbox.width}</div>
      </div>
    </>
  );
};

export { Wallbox };
export default Wallbox;
