import Cell from '../cell'; // eslint-disable-line
import Inclusion from '@app/models/inclusion';
import * as React from 'react';
import Table from '../table'; // eslint-disable-line
import classNames from 'classnames/bind';
import styles from './legend.module.css';

const cx = classNames.bind(styles);

type OwnProps = {
  inclusion: Inclusion;
};

const Legend = ({ inclusion }: OwnProps) => {
  const { categories } = inclusion;

  const equipment = Object.keys(categories).reduce((equipment_, key) => {
    const category = categories[key];
    Object.keys(category).forEach((equipmentCode) => {
      equipment_.push({
        name: equipmentCode,
        components: category[equipmentCode].join(', '),
      });
    });

    return equipment_;
  }, []);

  return (
    <Table className={cx({ outer: true })}>
      <thead>
        <tr>
          <Cell header highlight value="Legend" colSpan={2} />
        </tr>
        <tr>
          <Cell header value="Code" />
          <Cell header value="Name" />
        </tr>
      </thead>
      <tbody>
        {equipment.map((equipment_, index) => (
          <tr key={index}>
            <Cell value={equipment_.name} />
            <Cell value={equipment_.components} />
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export { Legend };
export default Legend;
