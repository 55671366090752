import * as React from 'react';
import classNames from 'classnames/bind';
import { rows as generateRows } from '@app/helpers/components';
import styles from './preview.module.css';

const cx = classNames.bind(styles);

type Row = {
  uuid?: string;
  id?: string;
  startsRow?: boolean;
};

const gridRenderer = <E extends React.ElementType = React.ElementType>(
  data: Row[],
  ItemRenderer: E,
  key: string,
  className: string = null,
  additionalProps: any = {}, // eslint-disable-line
) => {
  const rows = generateRows(data);

  return (
    <div className={cx({ grid: true, [className]: !!className })}>
      {rows.map((row, rowIndex) => (
        <div key={rowIndex} className={cx({ row: true })}>
          {row.map((item, itemIndex) => (
            <ItemRenderer
              key={`${item.id || item.uuid}-${itemIndex}`}
              {...{ [key]: item }}
              {...additionalProps}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export { gridRenderer, generateRows };
