import Action from '../types'; // eslint-disable-line
import Inclusion from '@app/models/inclusion';
import { Types } from './types';

const generateAttempt = (): Action<null> => ({
  type: Types.GENERATE_ATTEMPT,
  payload: null,
});

const generateSuccess = (inclusion: Inclusion): Action<Inclusion> => ({
  type: Types.GENERATE_SUCCESS,
  payload: inclusion,
});

export { generateAttempt, generateSuccess };
