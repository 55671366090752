import * as React from 'react';

import { Backplate } from './backplate';
import { Components } from './components';
import { Grids } from './grids';
import { Panel } from './panel';
import { PanelSet } from '@app/models/panel-set';
import { ServiceTypes } from './service-types';
import classNames from 'classnames/bind';
import styles from './preview.module.css';
import { logger } from '@app/helpers/log';

const log = logger('components:preview');

const cx = classNames.bind(styles);

type SizingMethod = 'parent' | 'viewport' | 'print' | 'a4' | 'a3';

type OwnProps = {
  factoryView?: boolean;
  fullView?: boolean;
  panelSet: PanelSet;
  componentRenderer?: React.FunctionComponent<any>;
  sizingMethod?: SizingMethod;
  className?: string;
  editing?: boolean;
  componentsLength: number;
  showCosts?: boolean;
  isDrawing?: boolean;
  showRoutes?: boolean;
};

let timeoutId;

const Preview = ({
  panelSet,
  componentRenderer = Components,
  fullView = false,
  factoryView = false,
  sizingMethod = 'viewport',
  className,
  editing,
  showCosts,
  showRoutes,
  componentsLength,
  isDrawing,
}: OwnProps) => {
  const { panel, backplate, gridContainer } = panelSet;

  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const groupRef = React.useRef<HTMLDivElement>(null);
  const panelRef = React.useRef<HTMLDivElement>(null);

  const Renderer = componentRenderer;
  const [minHeight, setMinHeight] = React.useState(100);
  const [transform, setTransform] = React.useState(null);
  const [horizontal, setHorizontal] = React.useState(false);
  const [longHorizontal, setLongHorizontal] = React.useState(false);

  const resize = () => {
    if (!panelRef.current || !groupRef.current || !wrapperRef.current) {
      return;
    }
    const { clientHeight, clientWidth } = document.body;
    const ARROW_OFFSETS = 100;
    const containingDimensions = { width: clientWidth, height: clientHeight };
    const previewDimensions = {
      width: groupRef.current.clientWidth + ARROW_OFFSETS,
      height: groupRef.current.clientHeight + ARROW_OFFSETS,
    };
    if (sizingMethod === 'print') {
      containingDimensions.width = 1400;
      containingDimensions.height = 595;
    } else if (
      sizingMethod === 'parent' ||
      sizingMethod === 'a3' ||
      sizingMethod === 'a4'
    ) {
      const { clientHeight: ch, clientWidth: cw } = wrapperRef.current
        .parentNode as HTMLDivElement;
      containingDimensions.width = cw;
      containingDimensions.height = ch;
    }

    let maxDimension = Math.max(
      previewDimensions.width,
      previewDimensions.height,
    );
    let largestSide: 'width' | 'height' =
      maxDimension === previewDimensions.width ? 'width' : 'height';
    if (sizingMethod === 'viewport') {
      maxDimension = previewDimensions.width;
      maxDimension += 100;
      largestSide = 'width';
    }
    const containingDimension = containingDimensions[largestSide];

    const scale = panel.hasComponents ? containingDimension / maxDimension : 1;

    log(
      panel.mspReference,
      sizingMethod,
      containingDimensions,
      previewDimensions,
      largestSide,
      containingDimension,
      scale,
    );

    if (scale < 1) {
      setTransform(`scale(${scale}) perspective(1px)`);
    } else {
      setTransform(null);
    }
  };

  React.useEffect(() => {
    const resizeHandler = () => {
      if (sizingMethod === 'viewport') {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(resize, 500);
      } else {
        resize();
      }
    };
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  React.useEffect(() => {
    resize();
  }, [minHeight, sizingMethod, componentsLength]);

  React.useEffect(() => {
    if (!groupRef.current) {
      return;
    }
    setMinHeight(groupRef.current.clientHeight + 100);
    const { clientWidth, clientHeight } = panelRef.current;
    if (clientHeight > clientWidth && !editing && panel.isVertical) {
      setHorizontal(true);
      if (clientHeight / clientWidth > 5) {
        // If the height is 5x the width, just show
        setLongHorizontal(true);
      }
    }
  });

  return (
    <div
      className={cx({
        wrapper: true,
        [className]: !!className,
        [sizingMethod]: true,
        showRoutes,
      })}
      style={{ minHeight: `${minHeight}px`, transform }}
    >
      <div className={styles.wrapperInternal} ref={wrapperRef}>
        <div
          className={cx({
            outer: true,
            factory: !!factoryView,
            horizontal,
            longHorizontal,
          })}
          ref={groupRef}
        >
          {fullView && factoryView && <ServiceTypes panel={panel} />}
          {fullView && <Grids grids={gridContainer.grids} />}
          <div className={styles.align} ref={panelRef}>
            <Panel panel={panel} isDrawing={isDrawing}>
              <Renderer
                components={panel.components}
                showCosts={showCosts}
                showRoutes={showRoutes}
              />
            </Panel>
          </div>
          <div className={styles.align}>
            <Backplate
              backplate={backplate}
              showCosts={showCosts}
              showRoutes={showRoutes}
              isDrawing={isDrawing}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { Preview, SizingMethod };
export default Preview;
