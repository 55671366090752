import { default as AlertModel, Complex } from '@app/models/alert';
import { Button, Icon, Icons } from '@bespohk/uikit/components';
import { operations } from '@app/state/ducks/alerts/operations';
import { isArray, isString } from '@bespohk/lib';

import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './alerts.module.css';
import { useOperations } from '@app/helpers/redux';

const cx = classNames.bind(styles);

type OwnProps = {
  alert: AlertModel;
};

const Alert = ({ alert }: OwnProps) => {
  const { remove } = useOperations(operations);

  const { type } = alert;
  let { message } = alert;
  let icon: Icons;
  switch (type) {
    case 'error':
    case 'warning':
      icon = Icons.ExclamationTriangle;
      break;
    case 'info':
      icon = Icons.InfoCircle;
      break;
    case 'success':
      icon = Icons.CheckCircle;
      break;
  }

  if (!message) {
    message = '';
  }

  const isBasicMessage = isString(message);

  const body = () => (
    <div className={cx({ body: true })}>
      {isBasicMessage
        ? message.toString()
        : isArray((message as Complex).body)
        ? ((message as Complex).body as string[]).map(
            (singleMessage, index) => <div key={index}>{singleMessage}</div>,
          )
        : (message as Complex).body}
    </div>
  );

  const title = () =>
    isBasicMessage ? null : (
      <div className={cx({ title: true })}>{(message as Complex).title}</div>
    );

  return (
    <div className={cx({ alert: true })}>
      <Icon name={icon} className={cx({ icon: true, [type]: true })} />
      <div>
        {title()}
        {body()}
      </div>
      <Button
        className={cx({ button: true })}
        label={<Icon name={Icons.Times} className={cx({ buttonIcon: true })} />}
        style="custom"
        action={() => remove(alert)}
      />
    </div>
  );
};

export { Alert };
export default Alert;
