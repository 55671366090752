import { Layout } from './layout';
import * as React from 'react';

type OwnProps = unknown;

const Customer = (_: OwnProps) => {
  return <Layout type="customer" showCutout />;
};

export { Customer };
export default Customer;
