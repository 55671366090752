import { Data, Component } from '@app/models/component';
import { last, last as lastItem, range } from '@bespohk/lib';

import Series from '@app/models/series';

const isPan = (component: Component, series: Series): boolean => {
  let keyToCheck = 'shortDescription';
  let panString = 'pan & id';

  if (series.name.toLowerCase().indexOf('meditek') > -1) {
    keyToCheck = 'equipmentCode';
    panString = 'm';
  }

  return component[keyToCheck].toLowerCase().indexOf(panString) > -1;
};

const areSpacersAndPredicate = (
  components: Component[],
  predicate: (component: Component) => boolean,
): boolean => {
  return components.every(
    (component) => predicate(component) || component.isSpacer,
  );
};

const containsAtLeastOneGas = (components: Component[]) =>
  components.some((component) => component.isGas);

const areSpacersAndGas = (components: Component[]): boolean => {
  return areSpacersAndPredicate(components, (component) => component.isGas);
};

const areSpacers = (components: Component[]): boolean =>
  components.reduce((valid: boolean, component) => {
    if (!component.isSpacer) {
      return false;
    }

    return valid;
  }, true);

const areSpacersAndOwnWallbox = (components: Component[]): boolean => {
  const predicate = (component) => component.isOwnWallbox;
  const hasOwnWallbox = components.some(predicate);
  const hasSpacersOrWallbox = areSpacersAndPredicate(components, predicate);

  return hasOwnWallbox && hasSpacersOrWallbox;
};

const width = (components: Component[]): number =>
  components.reduce((w: number, component) => {
    w += component.width;

    return w;
  }, 0);

const maxWidth = (components: Component[]): number =>
  components.reduce((w: number, component) => {
    if (component.rowStart) {
      return w;
    }
    w += component.width;

    return w;
  }, 0);

const rows = <T extends { startsRow?: boolean }>(data: T[]): T[][] => {
  return data.reduce(
    (rows_, d) => {
      if (d.startsRow && rows_[0].length) {
        rows_.push([]);
      }
      const row = lastItem(rows_) as unknown[];
      row.push(d);

      return rows_;
    },
    [[]],
  );
};

const offset = (
  components: Component[],
  index: number,
  include = false,
): number =>
  components.reduce((offset_, current, currentIndex) => {
    if (currentIndex < index) {
      offset_ += current.width;
    } else {
      if (include) {
        offset_ += current.width;
      }
    }

    return offset_;
  }, 0);

type Predicate = (component: Component, index: number, ...rest) => boolean;

const prevUntil = (
  components: Component[],
  index: number,
  predicate: Predicate,
): [number, Component] => {
  while (index > 0) {
    index--;
    const component = components[index];
    if (predicate(component, index)) {
      return [index, component];
    }
  }

  return [null, null];
};

const prev = (components: Component[], index: number): [number, Component] => {
  return prevUntil(components, index, (component) => !component.isSpacer);
};

const peekUntil = (
  components: Component[],
  index: number,
  predicate: Predicate,
): [number, Component] => {
  index++;
  while (index < components.length) {
    const component: Component = components[index];
    if (predicate(component, index)) {
      return [index, component];
    }
    index++;
  }

  return [null, null];
};

const peek = (components: Component[], index: number): [number, Component] => {
  return peekUntil(components, index, (component) => !component.isSpacer);
};

const peekUntilPan = (
  components: Component[],
  series: Series,
  index: number,
): [number, Component] => {
  return peekUntil(components, index, (component) => isPan(component, series));
};

const requiresData = (component: Component, data: Data[]): boolean => {
  return !!data.find((d) => d.matches(component));
};

const dataCount = (component: Component): number[] => {
  return range(1, component.quantity);
};

const missingData = (component: Component): boolean => {
  const specifiedDataCount: number = component.data
    ? component.data.split(',').length
    : 0;

  return specifiedDataCount !== dataCount(component).length;
};

const areRcds = (components: Component[]): boolean => {
  const isGasOnly = areSpacersAndGas(components);
  const strippedComponents = components.filter(
    (component) => !component.isSpacer,
  );

  return (
    !isGasOnly &&
    strippedComponents.reduce((p, c) => {
      if (c.isRcd || c.isAvam) {
        p += c.quantity;
      } else {
        p = 0;
      }

      return p;
    }, 0) > 3
  );
};

const lastComponentIsSpacer = (components: Component[]) => {
  return last(components).isSpacer;
};

export {
  isPan,
  areSpacersAndGas,
  areSpacers,
  areSpacersAndOwnWallbox,
  areSpacersAndPredicate,
  width,
  offset,
  prev,
  peek,
  peekUntilPan,
  rows,
  requiresData,
  dataCount,
  missingData,
  areRcds,
  maxWidth,
  prevUntil,
  peekUntil,
  containsAtLeastOneGas,
  lastComponentIsSpacer,
};
