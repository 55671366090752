import { Timestamps, mixin } from './common';

import Contact from './contact';
import Customer from './customer';
import { Panel } from './panel';
import { PanelSet } from './panel-set';
import Territory from './territory';
import User from './user';
import { formatDate } from '@app/helpers/dates';
import { formatter, rounding } from '@app/helpers/currency';
import { hydrate } from '@bespohk/lib';

enum Status {
  quote = 'Quote',
  out_for_approval = 'Out for approval',
  approved = 'Approved',
  rejected = 'Rejected',
  partially_approved = 'Partially approved',
  quote_lost = 'Quote lost',
}

class Offering {
  readonly id: number;
  name: string;
  type: string;
  default: boolean;
}

class Definition {
  readonly uuid: string;
  name: string;
  @hydrate
  customer: Customer;
  @hydrate
  contact: Contact;
  @hydrate
  point: User;
  @hydrate
  bdm: User;
  drawingReference?: string;
  salesOrderNumber: string;
  purchaseOrderNumber?: string;
  code: string;
  priority: number;
  territory: Territory;
  status: Status;
  isCad?: boolean;
  notes: string;
  @hydrate(Panel)
  panels: Panel[];
  @hydrate
  createdDate?: Date;
  @hydrate
  updatedDate?: Date;
  @hydrate(Offering)
  offerings?: Offering[];

  readonly revision = 1;
  @hydrate
  readonly validUntilDate?: Date = new Date(); // TODO: Replace

  public get humanValidUntilDate(): string {
    return formatDate(this.validUntilDate);
  }

  public toString(): string {
    return this.name;
  }
}

interface Project extends Timestamps {} // eslint-disable-line @typescript-eslint/no-empty-interface

mixin(Definition, [Timestamps]);

class Project extends Definition {
  @hydrate(PanelSet)
  panelSets: PanelSet[] = [];
  enabled?: boolean;
  totalPanels = 0; // Readonly from API

  public get hasPanels(): boolean {
    return !!this.panels.length;
  }

  public get activePanelSets(): PanelSet[] {
    return this.panelSets.filter((panelSet) => !panelSet.panel.deleted);
  }

  public get deletedPanelSets(): PanelSet[] {
    return this.panelSets.filter((panelSet) => panelSet.panel.deleted);
  }

  public get cost(): number {
    return rounding(
      this.activePanelSets.reduce((cost, panelSet) => {
        cost += panelSet.cost;

        return cost;
      }, 0),
    );
  }

  public get totalCost(): number {
    return rounding(
      this.activePanelSets.reduce((cost, panelSet) => {
        const { panelSet: panelSetCosts } = panelSet.totalCosts;
        cost += panelSetCosts.total;

        return cost;
      }, 0),
    );
  }

  public get humanCost(): string {
    return formatter.format(this.cost);
  }

  public get humanTotalCost(): string {
    return formatter.format(this.totalCost);
  }

  public get netCost(): number {
    return rounding(
      this.activePanelSets.reduce((cost, panelSet) => {
        const { panelSet: panelSetCosts } = panelSet.discountedTotalCosts;
        cost += panelSetCosts.total;

        return cost;
      }, 0),
    );
  }

  public get humanNetCost(): string {
    return formatter.format(this.netCost);
  }

  public isStatus(status: Status): boolean {
    return Status[this.status] === status;
  }
}

export { Project, Definition, Status, Offering };
export default Project;
