import { Screw } from '@app/models/screw';
import * as React from 'react';
import { TabularData } from '@app/containers';
import { BooleanColumnRenderer } from '@app/components';

const enabledRenderer = (screw: Screw): React.ReactNode => (
  <BooleanColumnRenderer valid={screw.enabled} />
);

const Screws = () => (
  <TabularData
    type={Screw}
    endpoint="/screws"
    identifier="partNumber"
    columns={[
      { key: 'description' },
      { key: 'partNumber', width: '20%' },
      { key: 'enabled', renderer: enabledRenderer },
      {
        key: 'type',
        width: '10%',
        // @ts-ignore It works...
        renderer: (value) => <>{value.humanType}</>,
      },
    ]}
    filter={{
      placeholder: 'Enter a part number to search for...',
      builder: (q: string) => (q ? `(partNumber: ${q}){*}` : undefined),
    }}
  />
);

export { Screws };
export default Screws;
