import { operations } from '@app/state/ducks/panel-builder/operations';
import * as React from 'react';

import { Component } from '@app/components/preview/components';
import ComponentModel from '@app/models/component';
import { Options } from './options';
import { Panel } from '@app/models/panel';
import State from '@app/state';
import classNames from 'classnames/bind';
import { generateRows } from '@app/components/preview/helpers';
import styles from './component-selection.module.css';
import { useOperations } from '@app/helpers/redux';
import { useSelector } from 'react-redux';

const cx = classNames.bind(styles);

type OwnProps = {
  showCosts?: boolean;
  showRoutes?: boolean;
};

const Handle = () => <div className={cx({ handle: true })}></div>;

const Components = ({ showCosts, showRoutes }: OwnProps) => {
  const { modifyComponent } = useOperations(operations);
  const panel: Panel = useSelector((state: State) => state.panelBuilder.panel);
  const [isDraggingIndex, setIsDraggingIndex] = React.useState(null);
  const [isDraggingOver, setIsDraggingOver] = React.useState(null);
  const [selectedComponent, setSelectedComponent] = React.useState(null);
  const [coords, setCoords] = React.useState([0, 0]);

  const isDragging = isDraggingIndex !== null;
  const rows = generateRows<ComponentModel>(panel.components);
  const componentsRef = React.useRef(null);

  React.useEffect(() => {
    const click = (e) => {
      if (componentsRef && !componentsRef.current.contains(e.target)) {
        setSelectedComponent(null);
      }
    };

    window.addEventListener('mouseup', click);

    return () => {
      window.removeEventListener('mouseup', click);
    };
  }, []);

  let index = 0;

  return (
    <div id="Components" ref={componentsRef}>
      {rows.map((row, rowIndex) => {
        return (
          <div key={rowIndex} className={styles.row}>
            {row.map((component) => {
              const actualIndex = index;
              index++;

              return (
                <React.Fragment key={actualIndex}>
                  {isDragging &&
                    actualIndex === isDraggingOver &&
                    actualIndex !== isDraggingIndex &&
                    actualIndex < isDraggingIndex && <Handle />}
                  <Component
                    component={component}
                    showCosts={showCosts}
                    showRoutes={showRoutes}
                    className={cx({
                      component: true,
                      spacer: component.isSpacer,
                      selected: selectedComponent === actualIndex,
                      strapping: panel.isVertical && panel.hasGas,
                      forcedEnd: component.forcedWallboxEnd,
                    })}
                    hasStrapping={panel.isVertical && panel.hasGas}
                    draggable
                    onClick={(event) => {
                      const target: any = event.target;
                      setCoords([
                        target.offsetLeft + 40,
                        target.offsetTop + 150,
                      ]);
                      setSelectedComponent(
                        selectedComponent === actualIndex ? null : actualIndex,
                      );
                    }}
                    onDragStart={() => {
                      setIsDraggingIndex(actualIndex);
                    }}
                    onDragOver={() => {
                      setIsDraggingOver(actualIndex);
                    }}
                    onDragEnd={() => {
                      if (isDraggingIndex !== isDraggingOver) {
                        modifyComponent(
                          component,
                          isDraggingIndex,
                          isDraggingOver,
                          null,
                        );
                      }
                      setIsDraggingIndex(null);
                      setIsDraggingOver(null);
                    }}
                  ></Component>
                  {!isDragging && (
                    <Options
                      order={actualIndex}
                      component={component}
                      show={selectedComponent === actualIndex}
                      coords={coords}
                    />
                  )}
                  {isDragging &&
                    actualIndex === isDraggingOver &&
                    actualIndex !== isDraggingIndex &&
                    actualIndex > isDraggingIndex && <Handle />}
                </React.Fragment>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export { Components };
export default Components;
