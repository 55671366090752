import { HANDLING_FEES, DEFAULT_HANDLING_FEE } from '@app/helpers/constants';
import { formatDate } from '@app/helpers/dates';

const handlingFeesForQuantity = (quantity: number): number => {
  const indexedQuantity = quantity > 0 ? quantity - 1 : 0;

  return HANDLING_FEES[indexedQuantity] || DEFAULT_HANDLING_FEE;
};

class Timestamps {
  createdDate?: Date;
  updatedDate?: Date;

  public get humanCreatedAt() {
    return formatDate(this.createdDate);
  }

  public get humanUpdatedAt() {
    return formatDate(this.updatedDate);
  }
}

function mixin(derivedCtor: any, baseCtors: any[]) {
  // https://www.typescriptlang.org/docs/handbook/mixins.html
  baseCtors.forEach((baseCtor) => {
    Object.getOwnPropertyNames(baseCtor.prototype).forEach((name) => {
      Object.defineProperty(
        derivedCtor.prototype,
        name,
        Object.getOwnPropertyDescriptor(baseCtor.prototype, name),
      );
    });
  });
}

type Dimensions = {
  width: number;
  height: number;
};

type Error = string;

export { Timestamps, mixin, handlingFeesForQuantity };
export type { Error, Dimensions };
