import { Grid } from './grid';
import { default as Model } from '@app/models/grid';
import { gridRenderer } from '../helpers'; // eslint-disable-line

type OwnProps = {
  grids: Model[];
};

const Grids = ({ grids }: OwnProps) => {
  return gridRenderer(grids, Grid, 'grid');
};

export { Grids };
export default Grids;
