import ToolType from '@app/models/tool-type';
import * as React from 'react';

import styles from './route.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

type OwnProps = {
  tooltypes?: ToolType[];
  allocation?: number;
  horizontalPunchPositions?: number;
  verticalPunchPositions?: number[];
  count?: number;
};

const Route = ({
  tooltypes,
  allocation,
  count,
  horizontalPunchPositions,
  verticalPunchPositions,
}: OwnProps) => {
  const types = tooltypes
    ? tooltypes.map((type) => (
        <div className={cx('tooltype')} key={type.code}>
          {type.code}
        </div>
      ))
    : undefined;

  return (
    <>
      {types && <div className={cx('types')}>{types}</div>}
      {horizontalPunchPositions && (
        <div className={cx('horizontal')}>
          {horizontalPunchPositions}
          {verticalPunchPositions && (
            <>
              <hr className={cx('vert')} />
              {verticalPunchPositions.map((p, i) => (
                <div key={i}>{p}</div>
              ))}
            </>
          )}
        </div>
      )}
      {allocation && <div className={cx('allocation')}>{allocation}</div>}
      {count && <div className={cx('count')}>{count}</div>}
    </>
  );
};

export { Route };
export default Route;
