class PanelType {
  readonly id: number;
  name: string;
  shortName: string;
  description: string;
  subAssemblyPrefix: string;
  active: boolean;

  public toString(): string {
    return this.name;
  }
}

export default PanelType;
