import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { rehydrate, storage } from '@bespohk/lib';

import { API_ENDPOINT, TOKEN_KEY } from './constants';
import { Pagination } from '@app/state/ducks/types';
import Token from '@app/models/token';

const REFRESH_ENDPOINT = '/auth/token/refresh';

const cancelToken = axios.CancelToken;

const generateEndpoint = (url: string) => {
  return `${API_ENDPOINT}${url}`;
};

const mergeWithDefaultConfig = (config: AxiosRequestConfig = {}) => {
  const requestConfig: AxiosRequestConfig = Object.assign({}, config);
  if (!requestConfig.headers) {
    requestConfig.headers = {};
  }
  const token: Token = storage.from(TOKEN_KEY, null);

  if (token && token.access) {
    requestConfig.headers.Authorization = `Bearer ${token.access}`;
  }

  return requestConfig;
};

const convertDataToTypes = (data: any, hydrationMethodOrClass: any): any => {
  let convertedData: any;
  try {
    convertedData = hydrationMethodOrClass(data);
  } catch (e) {} // eslint-disable-line
  if (!convertedData) {
    convertedData = rehydrate(hydrationMethodOrClass, data);
  }

  return convertedData;
};

const hydrateDataFromResponse = (
  response: AxiosResponse,
  hydrationMethodOrClass: any,
): any => {
  if ([200, 201, 202].indexOf(response.status) > -1) {
    const responseData: any = response.data;
    if (!hydrationMethodOrClass) {
      return responseData;
    }
    let data: any = null;
    const paginated: boolean =
      responseData && responseData.results && responseData.count !== null;
    if (paginated) {
      data = rehydrate(Pagination, responseData);
      data.results = convertDataToTypes(data.results, hydrationMethodOrClass);
    } else {
      data = convertDataToTypes(responseData, hydrationMethodOrClass);
    }

    return data;
  }

  return null;
};

type ApiErrors = {
  [field: string]: string[];
};

type ApiError = {
  message: string;
  errors: ApiErrors;
};

const extractErrors = (err: AxiosError<ApiError>): ApiErrors => {
  // Convenience method to convert errors from DRF into something suitable for
  // the form library.

  if (!err) {
    return {};
  }

  const { data, statusText } = err.response;
  let { errors } = data;

  if (errors === undefined) {
    // Likely a 500
    errors = {
      _: [statusText],
    };
  }

  return errors;
};

// Convenience HTTP methods

const get = async (
  url: string,
  hydrationMethodOrClass: any = null,
  config: AxiosRequestConfig = null,
): Promise<any> => {
  url = generateEndpoint(url);

  return axios.get(url, config).then((response: AxiosResponse) => {
    return hydrateDataFromResponse(response, hydrationMethodOrClass);
  });
};

const post = async (
  url: string,
  data: any = null,
  hydrateMethodOrClass: any = null,
  config: AxiosRequestConfig = null,
): Promise<any> => {
  url = generateEndpoint(url);

  return axios
    .post(url, data, config)
    .then((response: AxiosResponse) => {
      return hydrateDataFromResponse(response, hydrateMethodOrClass);
    })
    .catch((error: AxiosError) => {
      throw extractErrors(error);
    });
};

const put = async (
  url: string,
  data: any = null,
  hydrateMethodOrClass: any = null,
  config: AxiosRequestConfig = null,
): Promise<any> => {
  url = generateEndpoint(url);

  return axios
    .put(url, data, config)
    .then((response: AxiosResponse) => {
      return hydrateDataFromResponse(response, hydrateMethodOrClass);
    })
    .catch((error: AxiosError) => {
      throw extractErrors(error);
    });
};

const patch = async (
  url: string,
  data: any = null,
  hydrateMethodOrClass: any = null,
  config: AxiosRequestConfig = null,
): Promise<any> => {
  url = generateEndpoint(url);

  return axios
    .patch(url, data, config)
    .then((response: AxiosResponse) => {
      return hydrateDataFromResponse(response, hydrateMethodOrClass);
    })
    .catch((error: AxiosError) => {
      throw extractErrors(error);
    });
};

const del = async (
  url: string,
  hydrateMethodOrClass: any = null,
  config: AxiosRequestConfig = null,
): Promise<any> => {
  url = generateEndpoint(url);

  return axios
    .delete(url, config)
    .then((response: AxiosResponse) => {
      return hydrateDataFromResponse(response, hydrateMethodOrClass);
    })
    .catch((error: AxiosError) => {
      throw extractErrors(error);
    });
};

// Guard against the URL being called
const isApi = (url: string): boolean => !!url;

// Request Interceptor

axios.interceptors.request.use((axiosConfig) => {
  const config: AxiosRequestConfig = mergeWithDefaultConfig(axiosConfig);

  return config;
});

// Response Interceptor

let isFetchingNewAccessToken: boolean | null = false;

const waitForNewAccessToken = async (): Promise<any> => {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    if (isFetchingNewAccessToken !== true) {
      break;
    }
    await new Promise((resolve) => setTimeout(resolve, 500));
  }
};

const attemptFetchNewAccessToken = async (error?: AxiosError) => {
  const token: Token = storage.from(TOKEN_KEY, null);
  if (!token) {
    return Promise.reject(error);
  }
  isFetchingNewAccessToken = true;

  return post(REFRESH_ENDPOINT, { refresh: token.refresh }, Token)
    .then((token_) => {
      storage.to(TOKEN_KEY, token_);
      isFetchingNewAccessToken = false;

      return Promise.resolve(token_);
    })
    .catch((error_: AxiosError) => {
      isFetchingNewAccessToken = null;

      return Promise.reject(error_);
    });
};

axios.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const { response, config } = error;
    const { url } = config;
    if (isApi(url)) {
      if (axios.isCancel(error) || url.indexOf(REFRESH_ENDPOINT) > -1) {
        return Promise.reject(error);
      }
      if (isFetchingNewAccessToken) {
        await waitForNewAccessToken();
        if (isFetchingNewAccessToken === null) {
          return Promise.reject(error);
        }

        return Promise.resolve(axios(config));
      }
      if (response.status === 401) {
        if (!isFetchingNewAccessToken) {
          await attemptFetchNewAccessToken();
        }
        if (isFetchingNewAccessToken === false) {
          return Promise.resolve(axios(config));
        }
      } else {
        return Promise.reject(error);
      }
    }
  },
);

// Convenience method for querying endpoints
const LOOKUP_REQUESTS = {};
const lookup = (
  Type: any,
  url: string,
  callback: (resources: Pagination<any>) => any,
  queryBuilder?: any,
): any => {
  // Used to retrieve results from the API for selects
  return (query: string) => {
    return new Promise((resolve) => {
      let endpoint = `${url}`;
      if (queryBuilder) {
        const builtQuery = queryBuilder(query);
        if (builtQuery) {
          endpoint = `${endpoint}?query=${builtQuery}`;
        }
      }
      if (LOOKUP_REQUESTS[url]) {
        LOOKUP_REQUESTS[url].cancel('Existing request in progress');
      }
      LOOKUP_REQUESTS[url] = cancelToken.source();

      return get(endpoint, Type, {
        cancelToken: LOOKUP_REQUESTS[url].token,
      })
        .then((data: Pagination<any>) => {
          delete LOOKUP_REQUESTS[url];
          resolve(callback(data));
        })
        .catch((thrown) => {
          delete LOOKUP_REQUESTS[url];
          if (!axios.isCancel(thrown)) {
            resolve([]);
          }
        });
    });
  };
};

export { get, put, post, del, patch, lookup, extractErrors, ApiErrors };
