import * as yup from 'yup';

import { Choice, Input, Loader, Select } from '@bespohk/uikit/components';
import {
  choiceFromBoolean,
  choiceToBoolean,
  paginatedCustomerOptions,
  territoryOptions,
} from '@app/helpers/form';

import Customer from '@app/models/customer';
import { default as Model } from '@app/models/contact';
import { ModelForm } from '@app/containers';
import * as React from 'react';
import { get, lookup } from '@app/helpers/api';

type OwnProps = unknown;
const Contact = (_: OwnProps) => {
  const url = new URL(window.location.href);
  const customerId = url.searchParams.get('customerId');
  const [customer, setCustomer] = React.useState<Customer>(null);
  const [formValue, setFormValue] = React.useState<any>({
    enabled: [true],
  });

  React.useEffect(() => {
    if (customerId) {
      get(`/customers/${customerId}`, Customer).then((customer_) => {
        setFormValue({ ...formValue, customer: customer_ });
        setCustomer(customer_);
      });
    }
  }, []);

  if (customerId && !customer) {
    return <Loader label="Retrieving customer data..." />;
  }

  return (
    <ModelForm
      def={formValue}
      identifier="uuid"
      endpoint="/contacts"
      type={Model}
      initialTransform={yup.object().shape({
        enabled: choiceFromBoolean(),
        uuid: yup.mixed().strip(true),
        createdDate: yup.mixed().strip(true),
        updatedDate: yup.mixed().strip(true),
      })}
      preValidateTransform={yup.object().shape({
        enabled: choiceToBoolean(),
        customer: yup.mixed().convert('uuid'),
      })}
      validate={yup.object().shape({
        firstName: yup.string().required('First Name is a required field.'),
        lastName: yup.string().required('Last Name is a required field.'),
        email: yup.string().email().required('Email is a required field.'),
        phoneNumber: yup.string().required('Phone Number is a required field.'),
        suburb: yup.string().required('Suburb is a required field.'),
        state: yup.string().required('State is a required field.'),
        enabled: yup.boolean(),
      })}
      redirectBuilder={(contact) => {
        return `customerId=${customerId}&contactId=${contact.uuid}`;
      }}
      fieldsets={[
        [
          {
            name: 'customer',
            renderer: Select,
            props: {
              openOnFocus: true,
              async: lookup(
                Customer,
                '/customers',
                paginatedCustomerOptions,
                (q: string) => (q ? `(businessName:${q}){*}` : null),
              ),
            },
          },
        ],
        [
          { name: 'firstName', renderer: Input, props: { required: true } },
          { name: 'lastName', renderer: Input, props: { required: true } },
        ],
        [
          {
            name: 'email',
            renderer: Input,
            props: { type: 'email', required: true },
          },
          { name: 'phoneNumber', renderer: Input, props: { required: true } },
        ],
        [
          {
            name: 'additionalPhoneNumber',
            renderer: Input,
          },
          null,
        ],
        [
          { name: 'suburb', renderer: Input, props: { required: true } },
          {
            name: 'state',
            renderer: Select,
            props: {
              required: true,
              options: territoryOptions,
            },
          },
        ],
        [
          {
            name: 'enabled',
            label: null,
            renderer: Choice,
            props: {
              options: [{ value: true, label: 'Enabled' }],
            },
          },
        ],
      ]}
    />
  );
};

export { Contact };
export default Contact;
