import { Heading, Loader, Text, Table } from '@bespohk/uikit/components';
import { default as Model, Project } from '@app/models/project';
import { operations } from '@app/state/ducks/project/operations';
import * as React from 'react';

import State from '@app/state';
import { useOperations } from '@app/helpers/redux';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Panel } from '@app/models/panel';
import { BooleanColumnRenderer, NavigationButton } from '@app/components';
import { PanelSet } from '@app/models/panel-set';

import styles from './projects.module.css';

type OwnProps = unknown;

const pathForPanel = (project: Project, panel: Panel) =>
  `/projects/${project.uuid}/panels/${panel.uuid}?history`;

const History = (_: OwnProps) => {
  const params = useParams();
  const project: Model = useSelector((state: State) => state.project.data);
  const { fetch } = useOperations(operations);

  React.useEffect(() => {
    fetch(undefined, `/projects/${params['uuid']}?include=all`);
  }, []);

  if (!project) {
    return <Loader />;
  }

  return (
    <>
      <Heading
        value={`${project.name} - ${project.salesOrderNumber}`}
        size="medium"
      />
      <Table
        className={styles.table}
        columns={[
          {
            key: 'mspReference',
            label: 'MSP Ref',
            renderer: (panelSet: PanelSet) => (
              <>
                <NavigationButton
                  size="small"
                  label={panelSet.panel.mspReference}
                  path={pathForPanel(project, panelSet.panel)}
                />
              </>
            ),
          },
          {
            key: 'revision',
            label: 'Rev',
            renderer: (panelSet: PanelSet) => (
              <Text>{panelSet.panel.humanRevision}</Text>
            ),
          },
          {
            key: 'cost',
            label: 'Costs',
            renderer: (panelSet: PanelSet) => (
              <div className={styles.net}>
                <div className={styles.individual}>
                  {panelSet.humanNetUnitCost}
                </div>
                <div className={styles.combined}>
                  {panelSet.humanNetDiscountedCost}
                </div>
              </div>
            ),
          },
          {
            key: 'deleted',
            label: 'Deleted',
            renderer: (panelSet: PanelSet) => (
              <BooleanColumnRenderer valid={panelSet.panel.deleted} />
            ),
          },
          {
            key: 'humanUpdatedAt',
            label: 'Last Updated',
            renderer: (panelSet: PanelSet) => (
              <Text>{panelSet.panel.humanUpdatedAt}</Text>
            ),
          },
        ]}
        data={project.panelSets}
      />
    </>
  );
};

export { History };
export default History;
