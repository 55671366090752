import { Action, State } from '../types'; // eslint-disable-line
import { rehydrate, storage } from '@bespohk/lib';

import Token from '@app/models/token';
import { Types } from './types';
import User from '@app/models/user';
import { TOKEN_KEY, USER_KEY } from '@app/helpers/constants';

type ReducerState = { token: Token; user: User };

const initialState: State<ReducerState> = {
  loading: null,
  data: {
    token: storage.from(TOKEN_KEY, null),
    user: rehydrate(User, storage.from(USER_KEY, { roles: [] })),
  },
};

const blankState = {
  token: null,
  user: null,
};

const reducer = (
  state = initialState,
  action: Action<ReducerState | null>,
): State<ReducerState> => {
  const { type } = action;
  let payload = action.payload || state.data;
  let loading = null;

  switch (type) {
    case Types.LOGIN_SUCCESS:
      loading = false;
      break;

    case Types.LOGOUT_FAILURE:
      loading = false;
      payload = state.data;
      break;

    case Types.LOGOUT_REQUEST:
      loading = true;
      payload = blankState;
      break;

    case Types.LOGIN_REQUEST:
      loading = true;
      payload = blankState;
      break;

    case Types.LOGOUT_SUCCESS:
    case Types.LOGIN_FAILURE:
      loading = false;
      payload = blankState;
      break;

    default:
      return { loading, data: state.data };
  }

  return { loading, data: payload };
};

export { reducer };

export default reducer;
