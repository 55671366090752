import * as yup from 'yup';

import { Choice, Input, Select } from '@bespohk/uikit/components';
import {
  choiceFromBoolean,
  choiceToBoolean,
  paginatedPanelTypeOptions,
  panelTypeOptions,
} from '@app/helpers/form';

import { Wallbox as Model } from '@app/models/wallbox';
import { ModelForm } from '@app/containers';
import PanelType from '@app/models/panel-type';
import * as React from 'react';
import { lookup } from '@app/helpers/api';

type OwnProps = unknown;

const Wallbox = (_: OwnProps) => {
  return (
    <ModelForm
      identifier="uuid"
      endpoint="/wallboxes"
      type={Model}
      initialTransform={yup.object().shape({
        createdDate: yup.mixed().strip(true),
        updatedDate: yup.mixed().strip(true),
        uuid: yup.mixed().strip(true),
        gasOnly: choiceFromBoolean(),
        rcdBank: choiceFromBoolean(),
      })}
      preValidateTransform={yup.object().shape({
        gasOnly: choiceToBoolean(),
        rcdBank: choiceToBoolean(),
        panelType: yup.mixed().convert('id'),
      })}
      validate={yup.object().shape({
        code: yup.string().required('Code is a required field.'),
        gang: yup.number().required('Gang is a required field.'),
        width: yup.number().required('Width is a required field.'),
        cost: yup.number().required('Cost is a required field.'),
        description: yup.string().required('Description is a required field.'),
        standardPartNumber: yup.string(),
        panelType: yup.number().required('Panel Type is a required field.'),
      })}
      fieldsets={[
        [
          { name: 'code', renderer: Input, props: { required: true } },
          {
            name: 'standardPartNumber',
            renderer: Input,
          },
        ],
        [
          {
            name: 'gang',
            renderer: Input,
            props: { type: 'number', required: true },
          },
          {
            name: 'width',
            renderer: Input,
            props: { type: 'number', required: true },
          },
        ],
        [
          {
            name: 'cost',
            renderer: Input,
            props: { type: 'number', required: true },
          },
          null,
        ],
        [{ name: 'description', renderer: Input, props: { required: true } }],
        [
          {
            name: 'panelType',
            renderer: Select,
            props: {
              required: true,
              openOnFocus: true,
              options: panelTypeOptions,
              async: lookup(
                PanelType,
                '/panel-types',
                paginatedPanelTypeOptions,
              ),
            },
          },
        ],
        [
          {
            name: 'gasOnly',
            label: null,
            renderer: Choice,
            props: {
              options: [{ value: true, label: 'Is Gas Only' }],
            },
          },
        ],
        [
          {
            name: 'rcdBank',
            label: null,
            renderer: Choice,
            props: {
              options: [{ value: true, label: 'Is RCD Bank' }],
            },
          },
        ],
      ]}
    />
  );
};

export { Wallbox };
export default Wallbox;
