import { Loader } from '@bespohk/uikit/components';
import * as React from 'react';

type OwnProps = unknown;

const Loading = (_: OwnProps) => {
  return <Loader label="Retrieving drawings..." />;
};

export { Loading };
export default Loading;
