import { generateAttempt, generateSuccess } from './actions';

import { Dispatch } from 'redux';
import Inclusion from '@app/models/inclusion';
import { Project } from '@app/models/project';
import { generate as generateInclusion } from '@app/services/inclusion';
import { logger } from '@app/helpers/log';

const log = logger('ducks:inclusion:operations');

const generate =
  (project: Project): any =>
  (dispatch: Dispatch<any>) => {
    dispatch(generateAttempt());
    const inclusion: Inclusion = generateInclusion(project);
    dispatch(generateSuccess(inclusion));

    log(inclusion);

    return inclusion;
  };

type Operations = {
  generate: (project: Project) => Inclusion;
};

const operations: Operations = {
  generate,
};

export { operations, Operations };
