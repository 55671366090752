import { Component } from './component';

class Type {
  readonly id: number;
  name: string;

  public toString(): string {
    return this.name;
  }
}

type ServiceType = {
  name: string;
  components: Component[];
  startsRow?: boolean;
};

export default Type;

export { ServiceType };
