import * as yup from 'yup';

import { Input } from '@bespohk/uikit/components';
import { default as Model } from '@app/models/plate-finish';
import { ModelForm } from '@app/containers';
import * as React from 'react';

type OwnProps = unknown;

const PlateFinish = (_: OwnProps) => {
  return (
    <ModelForm
      identifier="id"
      endpoint="/plate-finishes"
      type={Model}
      initialTransform={yup.object().shape({
        id: yup.number().strip(true),
      })}
      preValidateTransform={yup.object().shape({
        name: yup.string().trim(),
        partNumber: yup.string().trim(),
        supplierCode: yup.string().trim(),
      })}
      validate={yup.object().shape({
        name: yup.string().required('Name is a required field.'),
        partNumber: yup.string().required('Part Number is a required field.'),
        supplierCode: yup
          .string()
          .required('Supplier Code is a required field.'),
      })}
      fieldsets={[
        [{ name: 'name', renderer: Input, props: { required: true } }],
        [
          { name: 'partNumber', renderer: Input, props: { required: true } },
          { name: 'supplierCode', renderer: Input, props: { required: true } },
        ],
      ]}
    />
  );
};

export { PlateFinish };
export default PlateFinish;
