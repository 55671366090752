import {
  Button,
  ButtonBar,
  Choice,
  Field,
  Form,
  Heading,
  Loader,
  Modal,
  Text,
} from '@bespohk/uikit/components';
import { operations } from '@app/state/ducks/resources/operations';
import { operations as projectOperations } from '@app/state/ducks/project/operations';
import * as React from 'react';

import { Offering } from '@app/models/project';
import State from '@app/state';
import { createSelector } from 'reselect';
import { groupOfferings } from '@app/helpers/projects';
import styles from './inclusions-prompt.module.css';
import { toUpperCaseWords } from '@bespohk/lib';
import { useOperations } from '@app/helpers/redux';
import { useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';

type OwnProps = {
  show?: boolean;
  cancel: () => void;
};

const projectSelector = (state: State) => state.project.data;
const offeringSelector = (state: State) =>
  state.resources.data.results as Offering[];
const loadingSelector = (state: State) => state.resources.loading;
const inclusionSelector = createSelector(
  projectSelector,
  offeringSelector,
  loadingSelector,
  (project, offerings, loading) => ({
    project,
    offerings,
    loading,
  }),
);

const InclusionsPrompt = ({ show, cancel }: OwnProps) => {
  const { fetch, clear } = useOperations(operations);
  const { setInclusionOfferings } = useOperations(projectOperations);
  const { project, offerings, loading } = useSelector(inclusionSelector);

  const route = useRouteMatch();

  React.useEffect(() => {
    if (show) {
      fetch(Offering, '/projects/options', 0, 500);
    }

    return () => {
      clear();
    };
  }, [show]);

  const groupedOfferings = groupOfferings(offerings);

  let { offerings: existingOfferings } = project;
  if (!existingOfferings.length && offerings) {
    existingOfferings = offerings.filter(
      (offering: Offering) => offering.default,
    );
  }
  const projectOfferings = groupOfferings(existingOfferings);

  return (
    <Modal when={show} onClose={() => cancel()} className={styles.window}>
      <Heading>Select offerings</Heading>
      <Form
        initial={projectOfferings}
        onSubmit={(values) => {
          const submittedOfferings = Object.values(values).reduce(
            (offerings_, current) => {
              // @ts-ignore FIX: this works...
              offerings_ = [...offerings_, ...current.map((o) => o.id)];

              return offerings_;
            },
            [],
          );
          setInclusionOfferings(project, submittedOfferings).then(() => {
            window.open(`${route.url}/inclusions`);
            cancel();
          });
        }}
      >
        {loading && <Loader label="Retrieving offerings..." />}
        {!loading && (
          <>
            <Text>
              Please select all options that are applicable to the project.
            </Text>
            <div className={styles.offerings}>
              {Object.keys(groupedOfferings)
                .sort()
                .map((offering) => (
                  <Field
                    label={toUpperCaseWords(offering)}
                    key={offering}
                    className={styles.group}
                  >
                    <Choice
                      name={offering}
                      options={groupedOfferings[offering].map(
                        (offering_: Offering) => ({
                          label: offering_.name,
                          value: offering_,
                        }),
                      )}
                    />
                  </Field>
                ))}
            </div>
          </>
        )}
        <ButtonBar layout="right">
          <Button action={() => cancel()} label="Cancel" style="secondary" />
          <Button label="OK" type="submit" />
        </ButtonBar>
      </Form>
    </Modal>
  );
};

export { InclusionsPrompt };
export default InclusionsPrompt;
