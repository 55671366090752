import PlateFinish from './plate-finish';
import { hydrate } from '@bespohk/lib';

class Material {
  description: string;
  partNumber: string;
  enabled: boolean;
  @hydrate(PlateFinish)
  plateFinishes: PlateFinish[];

  public toString(): string {
    return this.description;
  }

  public hasFinish = (plateFinish: PlateFinish): boolean => {
    return !!this.plateFinishes.find((finish) => finish.id === plateFinish.id);
  };
}

export default Material;
