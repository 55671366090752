import PlateFinish from './plate-finish';
import { hydrate } from '@bespohk/lib';

class Hbar {
  readonly id: number;
  description: string;
  partNumber: string;
  cost: number;
  @hydrate
  plateFinish: PlateFinish;

  public toString(): string {
    return this.description;
  }
}

export default Hbar;
