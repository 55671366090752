import { Icon, Icons } from '@bespohk/uikit/components';

import * as React from 'react';
import classNames from 'classnames/bind';
import { isString } from '@bespohk/lib';
import styles from './navigation-button.module.css';
import { useHistory } from 'react-router';

const cx = classNames.bind(styles);

type Callback = () => boolean | void;

type OwnProps = {
  icon?: Icons;
  path: string | Callback; // TODO: Type this to string or callback
  label: string | React.ReactNode;
  className?: string;
  size?: 'regular' | 'small';
  callback?: Callback;
};

const NavigationButton = ({
  path,
  label,
  className,
  callback,
  icon,
  size = 'regular',
}: OwnProps) => {
  const history = useHistory();

  const attrs = {
    href: isString(path) ? (path as string) : undefined,
  };

  return (
    <a
      {...attrs}
      className={cx({ button: true, [className]: !!className, [size]: true })}
      onClick={(e) => {
        e.preventDefault();
        if (callback) {
          if (callback()) {
            return;
          }
        }

        if (isString(path)) {
          history.push(path as string);
        } else {
          (path as Callback)();
        }
      }}
    >
      {icon && <Icon name={icon} className={cx({ icon: true })} />}
      {label}
    </a>
  );
};

export { NavigationButton };
export default NavigationButton;
