import { Timestamps, mixin } from './common';

import Customer from './customer';
import Territory from './territory';
import { hydrate } from '@bespohk/lib';

class Contact {
  readonly uuid: string;
  @hydrate
  customer: Customer;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  additionalPhoneNumber?: string;
  suburb: string;
  state: Territory;
  enabled?: boolean;

  public get name(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  public toString(): string {
    return this.name;
  }
}

mixin(Contact, [Timestamps]);

export default Contact;
