import * as yup from 'yup';

import { Choice, Input, Select } from '@bespohk/uikit/components';
import {
  choiceFromBoolean,
  choiceToBoolean,
  paginatedPanelTypeOptions,
  paginatedSeriesOptions,
} from '@app/helpers/form';

import { default as Model } from '@app/models/grid';
import { ModelForm } from '@app/containers';
import PanelType from '@app/models/panel-type';
import * as React from 'react';
import Series from '@app/models/series';
import { lookup } from '@app/helpers/api';

type OwnProps = unknown;

const Grid = (_: OwnProps) => {
  return (
    <ModelForm
      identifier="uuid"
      type={Model}
      endpoint="/grids"
      initialTransform={yup.object().shape({
        description: yup.string(),
        gasOnly: choiceFromBoolean(),
        ncOnly: choiceFromBoolean(),
        merlonNcOnly: choiceFromBoolean(),
        raulandOnly: choiceFromBoolean(),
        series: yup.array().convert('.'),
        panelType: yup.mixed().convert('.'),
        createdDate: yup.mixed().strip(true),
        updatedDate: yup.mixed().strip(true),
        uuid: yup.mixed().strip(true),
      })}
      preValidateTransform={yup.object().shape({
        gasOnly: choiceToBoolean(),
        ncOnly: choiceToBoolean(),
        merlonNcOnly: choiceToBoolean(),
        raulandOnly: choiceToBoolean(),
        series: yup.array().convert('id'),
        panelType: yup.mixed().convert('id'),
      })}
      validate={yup.object().shape({
        description: yup.string().required('Description is a required field.'),
        code: yup.string().required('Code is a required field.'),
        size: yup.string().required('Size is a required field.'),
        panelType: yup.string().required('Panel Type is a required field.'),
        series: yup.string().required('Series is a required field.'),
        gasOnly: yup.boolean(),
        ncOnly: yup.boolean(),
        merlonNcOnly: yup.boolean(),
      })}
      fieldsets={[
        [{ name: 'description', renderer: Input, props: { required: true } }],
        [
          { name: 'code', renderer: Input, props: { required: true } },
          {
            name: 'size',
            renderer: Input,
            props: { type: 'number', required: true },
          },
        ],
        [
          {
            name: 'panelType',
            renderer: Select,
            props: {
              required: true,
              openOnFocus: true,
              async: lookup(
                PanelType,
                '/panel-types',
                paginatedPanelTypeOptions,
              ),
            },
          },
        ],
        [
          {
            name: 'series',
            renderer: Select,
            props: {
              required: true,
              openOnFocus: true,
              multiple: true,
              async: lookup(Series, '/series', paginatedSeriesOptions),
            },
          },
        ],
        [
          {
            name: 'gasOnly',
            label: null,
            renderer: Choice,
            props: {
              options: [{ value: true, label: 'Is Gas Only' }],
            },
          },
        ],
        [
          {
            name: 'ncOnly',
            label: null,
            renderer: Choice,
            props: {
              options: [{ value: true, label: 'Is Nurse Call Only' }],
            },
          },
        ],
        [
          {
            name: 'merlonNcOnly',
            label: null,
            renderer: Choice,
            props: {
              options: [{ value: true, label: 'Is Merlon Nurse Call Only' }],
            },
          },
        ],
        [
          {
            name: 'raulandOnly',
            label: null,
            renderer: Choice,
            props: {
              options: [{ value: true, label: 'Is Rauland Only' }],
            },
          },
        ],
      ]}
    />
  );
};

export { Grid };
export default Grid;
