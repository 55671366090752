import { NavigationButton } from '@app/components';
import * as React from 'react';
import { Text } from '@bespohk/uikit/components';

type OwnProps = unknown;

const Error404 = (_: OwnProps) => {
  return (
    <div>
      <Text>There aren't the droids you're looking for.</Text>

      <NavigationButton label="Return" path="/" />
    </div>
  );
};

export { Error404 };
export default Error404;
