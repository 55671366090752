class RouteSetting {
  key: RouteSettingKey;
  value: number;

  public toString(): string {
    return this.key;
  }
}

type RouteSettingKey =
  | 'tool_type_minutes'
  | 'setup_time_vertical'
  | 'setup_time_horizontal'
  | 'additional_time_vertical'
  | 'additional_time_horizontal'
  | 'static_time'
  | 'plate_finish_stainless_steel'
  | 'plate_finish_other'
  | 'punch_position_82T'
  | 'punch_position_82P'
  | 'wallbox_additional'
  | 'wallbox_additional_per_row';

export default RouteSetting;
export { RouteSetting };
export type { RouteSettingKey };
