import { Action, State } from '../types'; // eslint-disable-line

import { Types } from './types';

const initialState: State<any> = {
  loading: null,
  data: null,
};

const reducer = (state = initialState, action: Action<any>): State<any> => {
  const { type } = action;
  let data = null;
  let loading = null;

  switch (type) {
    case Types.UPDATE_REQUEST:
    case Types.DELETE_REQUEST:
    case Types.CREATE_REQUEST:
    case Types.FETCH_REQUEST:
      data = state.data;
      loading = true;
      break;

    case Types.CREATE_SUCCESS:
    case Types.FETCH_SUCCESS:
    case Types.UPDATE_SUCCESS:
      loading = false;
      data = action.payload;
      break;

    case Types.CLEAR:
      loading = false;
      data = null;
      break;

    case Types.CREATE_ERROR:
    case Types.FETCH_ERROR:
    case Types.UPDATE_ERROR:
    case Types.DELETE_ERROR:
      loading = false;
      data = null;
      break;

    default:
      data = state.data;
      loading = state.loading;
  }

  return { loading, data };
};

export { reducer };

export default reducer;
