import React, { Fragment } from 'react';

import PlateFinish from '@app/models/plate-finish';
import { TabularData } from '@app/containers';

const PlateFinishes = () => (
  <Fragment>
    <TabularData
      type={PlateFinish}
      endpoint="/plate-finishes"
      identifier="id"
      filter={{
        placeholder: 'Enter a name to search for...',
        builder: (q: string) => (q ? `(name: ${q}){*}` : undefined),
      }}
      columns={[
        { key: 'name' },
        { key: 'partNumber' },
        { key: 'supplierCode' },
      ]}
    />
  </Fragment>
);

export { PlateFinishes };
export default PlateFinishes;
