import { zerofill } from '@app/helpers/strings';

const formatDate = (d: Date): string =>
  !d
    ? null
    : `${zerofill(d.getDate(), 2)}/${zerofill(
        d.getMonth() + 1,
        2,
      )}/${d.getFullYear()} - ${zerofill(d.getHours(), 2)}:${zerofill(
        d.getMinutes(),
        2,
      )}:${zerofill(d.getSeconds(), 2)}`;

export { formatDate };
