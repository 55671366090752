import {
  Choice,
  Heading,
  Icons,
  Prompt,
  Table,
  Text,
} from '@bespohk/uikit/components';
import { operations } from '@app/state/ducks/panel-builder/operations';
import { Project, Status } from '@app/models/project';
import { operations as projectOperations } from '@app/state/ducks/project/operations';
import * as React from 'react';

import { NavigationButton } from '@app/components';
import { Panel } from '@app/models/panel';
import { PanelSet } from '@app/models/panel-set';
import State from '@app/state';
import User from '@app/models/user';
import classNames from 'classnames/bind';
import styles from './projects.module.css';
import { useOperations } from '@app/helpers/redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

const cx = classNames.bind(styles);

const projectSelector = (state: State) => state.project.data;
const userSelector = (state: State) => state.auth.data.user;

const pathForPanel = (project: Project, panel: Panel) =>
  `/projects/${project.uuid}/panels/${panel.uuid}`;

type OwnProps = unknown;

const approvalRenderer = (
  key: 'approved' | 'manufacturingApproved',
  project: Project,
  operation: any,
): any => {
  // TODO: Role restriction
  return (panelSet: PanelSet) =>
    !panelSet.deleted && (
      <NavigationButton
        className={cx({ notApproved: !panelSet.panel[key] })}
        size="small"
        icon={Icons.CheckCircle}
        label={panelSet.panel[key] ? 'Un-approve' : 'Approve'}
        path={() =>
          operation(project, panelSet.panel, key, !panelSet.panel[key])
        }
      />
    );
};

const Drawings = (_: OwnProps) => {
  const history = useHistory();
  const { del } = useOperations(operations);
  const { createRevision, undelete, approve } =
    useOperations(projectOperations);
  const project: Project = useSelector(projectSelector);
  const user: User = useSelector(userSelector);
  const [panelToDelete, setPanelToDelete] = React.useState<Panel>(null);
  const [panelToRevision, setPanelToRevision] = React.useState<Panel>(null);
  const [showDeleted, setShowDeleted] = React.useState(false);

  if (!project) {
    return null;
  }

  const { deletedPanelSets } = project;
  const deletedCount = deletedPanelSets.length;
  const panels =
    showDeleted && deletedPanelSets.length > 0
      ? deletedPanelSets
      : project.activePanelSets;

  const columns = [
    {
      key: 'mspReference',
      label: 'MSP Ref',
      renderer: (panelSet: PanelSet) => (
        <>
          <NavigationButton
            size="small"
            label={panelSet.panel.mspReference}
            path={pathForPanel(project, panelSet.panel)}
          />
          <Text className={cx({ yyNumber: true })}>
            {panelSet.panel.yyNumber}
            <br />
            {panelSet.panel.humanUpdatedAt}
          </Text>
        </>
      ),
    },
    {
      key: 'revision',
      width: '10%',
      label: 'Rev',
      renderer: (panelSet: PanelSet) => (
        <Text className={cx({ revision: true })}>
          {panelSet.panel.humanRevision}
        </Text>
      ),
    },
    {
      key: 'actions',
      width: '20%',
      renderer: (panelSet: PanelSet) => (
        <div className={styles.actions}>
          {!panelSet.panel.approved && !panelSet.panel.deleted && (
            <NavigationButton
              size="small"
              label="Edit"
              icon={Icons.Edit}
              path={pathForPanel(project, panelSet.panel)}
            />
          )}
          {!project.isCad && (
            <NavigationButton
              size="small"
              label={panelSet.panel.deleted ? 'Undelete' : 'Delete'}
              icon={Icons.Trash}
              path={() => {
                if (panelSet.panel.deleted) {
                  undelete(project, panelSet.panel);

                  return;
                }
                setPanelToDelete(panelSet.panel);
              }}
            />
          )}
          {!panelSet.panel.approved &&
            !panelSet.panel.deleted &&
            !project.isCad &&
            !project.isStatus(Status.quote_lost) &&
            !project.isStatus(Status.rejected) && (
              <NavigationButton
                size="small"
                label="New Revision"
                icon={Icons.Plus}
                path={() => setPanelToRevision(panelSet.panel)}
              />
            )}
        </div>
      ),
    },
  ];

  if (user.isAdmin || user.isOperations) {
    columns.splice(2, 0, {
      key: 'manufacturingApproved',
      width: '20%',
      label: 'Ops Approved',
      renderer: approvalRenderer('manufacturingApproved', project, approve),
    });
  }

  if (user.isAdmin || user.isSales) {
    columns.splice(2, 0, {
      key: 'approved',
      width: '20%',
      renderer: approvalRenderer('approved', project, approve),
    });
  }

  return (
    <>
      <Heading size="medium">
        Quotation / Drawings{' '}
        <NavigationButton
          className={styles.add}
          icon={Icons.PlusCircle}
          label="Add Drawing"
          path={`/projects/${project.uuid}/panels/new`}
        />
      </Heading>
      {deletedCount > 0 && (
        <Choice
          name="showDeleted"
          onChange={() => {
            setShowDeleted(!showDeleted);
          }}
          value={[showDeleted]}
          options={[
            {
              label: `${
                showDeleted ? 'Hide' : 'Show'
              } deleted (${deletedCount})`,
              value: true,
            },
          ]}
        />
      )}
      <Table<PanelSet>
        className={styles.table}
        columns={columns}
        data={panels}
      />
      <Prompt
        title="Delete Panel?"
        message={`Are you sure you want to delete ${
          panelToDelete ? panelToDelete.mspReference : null
        }?`}
        when={!!panelToDelete}
        positive={{
          label: 'Yes',
          action: async () => {
            await del(project, panelToDelete);
            setPanelToDelete(null);
          },
        }}
        negative={{
          label: 'No',
          action: () => setPanelToDelete(null),
        }}
      />
      <Prompt
        title="Create Revision?"
        message={`Are you sure you want to create a revision for ${
          panelToRevision ? panelToRevision.mspReference : null
        } (to REV ${panelToRevision ? panelToRevision.revision + 1 : null})?`}
        when={!!panelToRevision}
        positive={{
          label: 'Yes',
          action: async () => {
            const revision: any = await createRevision(
              project,
              panelToRevision,
            );
            history.push(pathForPanel(project, revision));
            setPanelToRevision(null);
          },
        }}
        negative={{
          label: 'No',
          action: () => setPanelToRevision(null),
        }}
      />
    </>
  );
};

export { Drawings };
export default Drawings;
