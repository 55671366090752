import { isObject, isString } from '@bespohk/lib';

/**
 * download()
 *
 * @param data The data to output to the browser for download
 * @param filename the filename to output as
 */

const download = (
  data: string,
  filename: string,
  type = 'application/octet-stream',
) => {
  const link = document.createElement('a');
  const blob = new Blob([data], { type });
  link.download = filename;
  link.href = window.URL.createObjectURL(blob);
  link.click();
};

/**
 *
 * @param data The data to echo to the console
 */
const log = (data: unknown): void => {
  if (console && console.log) {
    let method = 'log';
    if (isString(data) && (data as string).startsWith('<')) {
      method = 'dirxml';
      const parser: DOMParser = new DOMParser();
      data = parser.parseFromString(data as string, 'application/xml');
    } else if (isObject(data)) {
      method = 'dir';
    }
    console[method](data);
  }
};

const scroll = (y = 0) => window.scrollTo(0, y);

export { download, log, scroll };
