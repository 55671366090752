class Strapping {
  description: string;
  partNumber: string;
  rows: number;
  centers: number;
  enabled: boolean;

  public toString(): string {
    return this.description;
  }
}

export default Strapping;
