import {
  Action,
  addNewPanel,
  clear as clearAction,
  createError,
  createRequest,
  createRevisionError,
  createRevisionRequest,
  createRevisionSuccess,
  createSuccess,
  deletePanel as deletePanelAction,
  duplicateError,
  duplicateRequest,
  duplicateSuccess,
  fetchError,
  fetchRequest,
  fetchSuccess,
  updateError,
  updateRequest,
  updateSuccess,
  setOfferings,
  undeleteRequest,
  undeleteSuccess,
  approveRequest,
  approveSuccess,
  createTestError,
  createTestRequest,
  createTestSuccess,
} from './actions';
import { ApiErrors, get, patch, post, put } from '@app/helpers/api';
import { Project, Definition as ProjectDefinition } from '@app/models/project';
import { Dispatch } from 'redux';
import { Panel } from '@app/models/panel';
import { PanelSet } from '@app/models/panel-set';
import { operations as alertOperations } from '../alerts/operations'; // eslint-disable-line
import { generate } from '@app/services/project';
import { fetch as fetchLookups } from '@app/services/lookups';

const { add } = alertOperations;

const fetchProject = (path: string) =>
  Promise.all<any>([fetchLookups(), get(path, ProjectDefinition)]).then(
    (data) => {
      const [lookups, projectDefinition] = data;

      const project: Project = generate(projectDefinition, lookups);

      return project;
    },
  );

// These are overrides for the Resource duck
const fetch =
  (_, path: string): any =>
  (dispatch: Dispatch<Action>) => {
    dispatch(fetchRequest());

    return fetchProject(path)
      .then((project) => {
        dispatch(fetchSuccess(project));
      })
      .catch((__) => dispatch(fetchError()));
  };

const create = (path: string, data: any): any => {
  return (dispatch: Dispatch<any>): any => {
    dispatch(createRequest());

    return post(path, data, Project)
      .then((project: Project) => {
        dispatch(createSuccess(project));
        add('success', `Created ${project.name} successfully.`, 4)(dispatch);
      })
      .catch((errors: ApiErrors) => {
        dispatch(createError());
        add(
          'error',
          'Failed to create project, please correct any errors and try again',
          4,
        )(dispatch);
        throw errors;
      });
  };
};

const update = (path: string, data: any): any => {
  return (dispatch: Dispatch<any>): any => {
    dispatch(updateRequest());

    return patch(path, data, ProjectDefinition)
      .then((definition: ProjectDefinition) => {
        add('success', `Updated ${definition.name} successfully.`, 4)(dispatch);
        fetchProject(path).then((project: Project) => {
          dispatch(updateSuccess(project));
        });
      })
      .catch((errors: ApiErrors) => {
        dispatch(updateError());
        add(
          'error',
          'Failed to update project, please correct any errors and try again',
          4,
        )(dispatch);
        throw errors;
      });
  };
};

const clear = (): any => (_: Dispatch<any>) => {
  // dispatch(clearAction());
};

const listClear = (): any => (dispatch: Dispatch<any>) => {
  dispatch(clearAction());
};

const addPanel =
  (project: Project, panel: Panel, panelSet: PanelSet): any =>
  (dispatch: Dispatch<any>) => {
    dispatch(addNewPanel(project, panel, panelSet));
  };

const deletePanel =
  (project: Project, panel: Panel): any =>
  (dispatch: Dispatch<any>) => {
    dispatch(deletePanelAction(project, panel));
  };

const setInclusionOfferings =
  (project: Project, offerings: any[]) => (dispatch: Dispatch<Action>) => {
    // TODO: Identify why this is coming through as string[] rather than number[]
    return patch(`/projects/${project.uuid}`, {
      offerings: offerings,
    }).then((patchedProject: ProjectDefinition) => {
      dispatch(setOfferings(project, patchedProject.offerings));
    });
  };

const createRevision =
  (project: Project, panel: Panel): any =>
  (dispatch: Dispatch<any>) => {
    dispatch(createRevisionRequest(project, panel));

    return get(`/projects/${project.uuid}/panels/${panel.uuid}/revision`)
      .then((_) => {
        dispatch(createRevisionSuccess(project, panel));
        add(
          'success',
          `Created revision for ${panel.mspReference} successfully.`,
          4,
        )(dispatch);

        return fetch(
          null,
          `/projects/${project.uuid}`,
        )(dispatch).then(() => {
          return _;
        });
      })
      .catch((errors: ApiErrors) => {
        dispatch(createRevisionError(project, panel));
        add(
          'error',
          `Failed to create revision for ${panel.mspReference}, please try again`,
          4,
        )(dispatch);
        throw errors;
      });
  };

const duplicate =
  (project: Project): any =>
  (dispatch: Dispatch<any>) => {
    dispatch(duplicateRequest(project));

    return post(`/projects/${project.uuid}/duplicate`, null, Project)
      .then((dupe) => {
        add('success', `Duplicated ${project.name} successfully.`, 4)(dispatch);
        dispatch(duplicateSuccess(project));

        return dupe;
      })
      .catch((errors: ApiErrors) => {
        dispatch(duplicateError(project));
        add(
          'error',
          `Failed to duplicate ${project.name}, please try again.`,
          4,
        )(dispatch);
        throw errors;
      });
  };

const refreshPricing =
  (project: Project): any =>
  (dispatch: Dispatch<any>) => {
    return put(`/projects/${project.uuid}/refresh-pricing`)
      .then(() => {
        add('success', 'Successfully updated pricing.', 4)(dispatch);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((errors: ApiErrors) => {
        add(
          'error',
          'Failed to update pricing, please try again.',
          4,
        )(dispatch);
        throw errors;
      });
  };

const sendForApproval =
  (project: Project, date: string): any =>
  (dispatch: Dispatch<any>) => {
    return post('/projects/approvals', {
      project: project.uuid,
      sendDate: `${date} 00:00:01.000000`,
    })
      .then(() => {
        add(
          'success',
          `Successfully scheduled ${project.name} for approval.`,
          4,
        )(dispatch);
      })
      .catch((errors: ApiErrors) => {
        add(
          'error',
          'Failed to send for approval, please try again.',
          4,
        )(dispatch);
        throw errors;
      });
  };

const undelete =
  (project: Project, panel: Panel): any =>
  (dispatch: Dispatch<any>) => {
    dispatch(undeleteRequest(project, panel));

    return get(`/projects/${project.uuid}/panels/${panel.uuid}/undelete`)
      .then(() => {
        add(
          'success',
          `Undeleted ${panel.mspReference} successfully.`,
          4,
        )(dispatch);
        dispatch(undeleteSuccess(project, panel));
      })
      .catch(() => {
        add(
          'error',
          `Failed to undelete ${panel.mspReference}, please try again`,
          4,
        )(dispatch);
      });
  };

type ApprovalType = 'manufacturingApproved' | 'approved';

const approve =
  (project: Project, panel: Panel, type: ApprovalType, valid: boolean): any =>
  (dispatch: Dispatch<any>) => {
    dispatch(approveRequest(project, panel, type, valid));

    return patch(`/projects/${project.uuid}/panels/${panel.uuid}`, {
      [type]: valid,
    })
      .then((_) => {
        add('success', `Approved ${panel.mspReference}`)(dispatch);
        dispatch(approveSuccess(project, panel, type, valid));
      })
      .catch((errors: ApiErrors) => {
        add(
          'error',
          `Failed to approve ${panel.mspReference}, please try again.`,
        )(dispatch);
        throw errors;
      });
  };

const createTest = () => (dispatch: Dispatch<any>) => {
  dispatch(createTestRequest());

  return post('/projects/create-test')
    .then((project) => {
      add(
        'success',
        `Created test project ${project.name} successfully.`,
        4,
      )(dispatch);

      dispatch(createTestSuccess(project));
    })
    .catch((_) => {
      dispatch(createTestError());
    });
};

type Operations = {
  addPanel: (
    project: Project,
    panel: Panel,
    panelSet: PanelSet,
  ) => Dispatch<any>;
  deletePanel: (project: Project, panel: Panel) => Dispatch<any>;
  fetch: (_, path: string) => Promise<any>;
  update: (path: string, data: any) => void;
  create: (path: string, data: any) => void;
  setInclusionOfferings: (project: Project, offerings: any[]) => any;
  clear: () => void;
  listClear: () => void;
  createRevision: (project: Project, panel: Panel) => Dispatch<any>;
  duplicate: (project: Project) => Dispatch<any>;
  refreshPricing: (project: Project) => Dispatch<any>;
  sendForApproval: (project: Project, date: string) => Dispatch<any>;
  undelete: (project: Project, panel: Panel) => Promise<any>;
  createTest: () => void;
  approve: (
    project: Project,
    panel: Panel,
    type: ApprovalType,
    valid: boolean,
  ) => Promise<any>;
};

const operations: Operations = {
  addPanel,
  deletePanel,
  fetch,
  clear,
  create,
  update,
  setInclusionOfferings,
  createRevision,
  duplicate,
  refreshPricing,
  sendForApproval,
  listClear,
  undelete,
  approve,
  createTest,
};

export { operations, Operations };
