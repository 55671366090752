import ToolType from '@app/models/tool-type';
import * as React from 'react';
import { TabularData } from '@app/containers';

const ToolTypes = () => (
  <TabularData
    type={ToolType}
    endpoint="/components/tool-types"
    identifier="id"
    columns={[{ key: 'code' }]}
    filter={{
      placeholder: 'Enter a code to search for...',
      builder: (q: string) => (q ? `(code: ${q}){*}` : undefined),
    }}
  />
);

export { ToolTypes };
export default ToolTypes;
