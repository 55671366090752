import { Image, Wrapper } from '@bespohk/uikit/components';

import * as React from 'react';
import { asset } from '@app/helpers/assets';
import classNames from 'classnames/bind';
import styles from './print.module.css';

const cx = classNames.bind(styles);

type OwnProps = {
  withHeader?: boolean;
  children: any[];
};

const Print = ({ children, withHeader }: OwnProps) => {
  return (
    <div className={cx({ print: true })}>
      {withHeader && (
        <div className={cx({ header: true })}>
          <Wrapper>
            <Image
              src={asset('/img/logo.svg')}
              className={cx({ logo: true })}
            />
          </Wrapper>
        </div>
      )}
      {children.map((child, index) => (
        <div key={index} className={cx({ page: true })}>
          {child}
        </div>
      ))}
    </div>
  );
};

export { Print };
export default Print;
