import { Layout } from './layout';
import * as React from 'react';

type OwnProps = unknown;

const Construction = (_: OwnProps) => {
  return <Layout type="construction" showCutout />;
};

export { Construction };
export default Construction;
