import { Wrapper, useTheme } from '@bespohk/uikit/components';

import { Branding } from './branding';
import { Button } from './button';
import * as React from 'react';
import State from '@app/state';
import styles from './menu.module.css';
import { useSelector } from 'react-redux';

type OwnProps = unknown;

const Menu = (_: OwnProps) => {
  const cx = useTheme('Menu', styles);
  const user = useSelector((state: State) => state.auth.data.user);

  return (
    <div className={cx({ outer: true })}>
      <Branding />
      <div className={cx({ menuOuter: true })}>
        <Wrapper>
          <Button label="Projects" path="/projects" />
          <Button label="Approvals" path="/projects/approvals" />
          <Button label="Contacts" path="/contacts" />
          <Button label="Customers" path="/customers" />
          {user.isAdmin && <Button label="Users" path="/users" />}
          <Button
            label="Logout"
            path="/auth/logout"
            className={styles.logout}
          />
        </Wrapper>
      </div>
    </div>
  );
};

export { Menu };
export default Menu;
