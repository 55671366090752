class Token {
  access: string;
  refresh: string;

  public get bearer(): string {
    return `Bearer ${this.access}`;
  }
}

export default Token;
